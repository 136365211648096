import React, { useState, useEffect } from 'react';
import styles from './style';
import { useNavigation } from '@react-navigation/native';
import { View, TouchableOpacity, TextInput, FlatList, Text, } from 'react-native';
import axios from 'axios';
import { StackNavigationProp } from '@react-navigation/stack';
import { StackParamList } from '../../navigators/navigationTypes';
import Image from '../UtilityComponents/UniversalImage';
import CustomAlert from '../Hooks/Alert/Alert';

import { useSearch } from '../../hooks/user/useSearch';
import { User } from '../../hooks/user/UserTypes';

import { API_URL } from '@env';

const UserSearch: React.FC = () => {

  const [query, setQuery] = useState('');
  const [imageErrors, setImageErrors] = useState<Set<string>>(new Set());

  const [alertVisible, setAlertVisible] = useState(false);
  const [alertTitle, setAlertTitle] = useState('');
  const [alertMessage, setAlertMessage] = useState('');
  const [navigationError, setNavigationError] = useState(false);

  const { users, setUsers, setTopUsers, topUsers, handleSearch, fetchTopUsers } = useSearch();

  const navigation = useNavigation<StackNavigationProp<StackParamList>>();

  useEffect(() => {
    fetchTopUsers(); // Fetch top users when component mounts
  }, []);

  useEffect(() => {
    const debounceTimeout = setTimeout(() => {
      if (query.trim() !== '') {
        handleSearch(query);
        setTopUsers([]); // Clear top users when search begins
      } else {
        setUsers([]); // Reset the search results if the query is empty
        fetchTopUsers(); // Fetch top users again when the search query is cleared
      }
    }, 500); // 500 milliseconds debounce period

    return () => clearTimeout(debounceTimeout); // Clear timeout on component unmount
  }, [query]);
  

  const handleUserPress = async (userProfile: User) => {
    try {
        if (!userProfile.profilePicture) {
            throw new Error('User avatar is not available.');
        }

        // Assuming 'navigation' is already defined in your component
        const response = await axios.get(`${API_URL}/users/exists/${userProfile.username}`,
          {
            withCredentials: true,
          }
        );

        if( response.data.exists ){
          navigation.navigate('UserView', {
            username: userProfile.username,
          });
        } else {
          setAlertTitle('Navigation Error');
          setAlertMessage('Failed to navigate to user profile. The user you may be banned, recently deleted their profile, or we may be experiencing network issues.');
          setNavigationError(true);
          setAlertVisible(true);
        }
    } catch (error: any) {
        console.error(error);
        setAlertTitle('Navigation Error');
        setAlertMessage(error.message || 'Failed to navigate to user view.');
        setNavigationError(true);
        setAlertVisible(true);
    }
  };

  const renderItem = ({ item }: { item: User }) => {
    const onImageError = () => {
      // Add the user ID to the set of image errors
      setImageErrors(prevErrors => new Set(prevErrors).add(item.username));
    };
  
    // Determine the image source based on whether there was an error
    const imageSource = imageErrors.has(item.username)
      ? { uri: 'https://storage.googleapis.com/clipdle-profile-pics/Default_pfp.jpeg' }
      : { uri: `https://storage.googleapis.com/clipdle-profile-pics/${item.username}_thumbnail.jpeg` };
  
    return (
      <TouchableOpacity onPress={() => handleUserPress(item)}>
        <View style={styles.userItem}>
          <Image
            source={imageSource}
            style={styles.commentAvatar}
            onError={onImageError}
          />
          <Text style={styles.userText}>{item.username}</Text>
        </View>
      </TouchableOpacity>
    );
  };
  

  return (
    <View style={styles.container}>
      <View style={styles.searchBar}>
        <TextInput
          style={styles.input}
          value={query}
          onChangeText={setQuery}
          cursorColor={'white'}
          placeholder="Search for users..."
          placeholderTextColor={'white'}
          autoCapitalize="none"
        />
      </View>

      {/* Top Users List */}
      {topUsers.length > 0 && query === '' && (
    <View>
        <Text style={styles.sectionTitle}>Top Users This Month</Text>
        {/* Render top users */}
        {topUsers.map((user) => {
            const onImageError = () => {
                // Add the user ID to the set of image errors
                setImageErrors(prevErrors => new Set(prevErrors).add(user.username));
            };

            // Determine the image source based on whether there was an error
            const imageSource = imageErrors.has(user.username)
                ? { uri: 'https://storage.googleapis.com/clipdle-profile-pics/Default_pfp.jpeg' }
                : { uri: `https://storage.googleapis.com/clipdle-profile-pics/${user.username}_thumbnail.jpeg` };

            return (
                <TouchableOpacity key={user.username} onPress={() => handleUserPress(user)}>
                    <View style={styles.userItem}>
                        <Image
                            source={imageSource}
                            style={styles.commentAvatar}
                            onError={onImageError}
                        />
                        <Text style={styles.userText}>{user.username}</Text>
                    </View>
                </TouchableOpacity>
            );
        })}

        {navigationError && (
            <CustomAlert
                isVisible={alertVisible}
                title={alertTitle}
                message={alertMessage}
                buttons={[
                    {
                        text: 'OK',
                        onPress: () => {
                            setAlertVisible(false);
                            setNavigationError(false);
                        },
                    },
                ]}
                onClose={() => {
                    setAlertVisible(false);
                    setNavigationError(false);
                }}
            />
        )}
    </View>
)}

      <FlatList
        data={users}
        keyExtractor={(item) => item.username}
        renderItem={renderItem}
      />

    </View>
  );
};



export default UserSearch;