import React, { useState, useEffect } from 'react';
import {
  Modal,
  View,
  Text,
  TouchableOpacity,
  ScrollView,
  TextInput,
  KeyboardAvoidingView,
  Platform, 
} from 'react-native';
import { useAuth } from '../../context/AuthContext';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { StackParamList } from '../../navigators/navigationTypes';
import axios from 'axios';
import { GestureHandlerRootView, PanGestureHandler } from 'react-native-gesture-handler';
import { useSharedValue, useAnimatedStyle, useAnimatedGestureHandler, withSpring, runOnJS } from 'react-native-reanimated';
import Animated from 'react-native-reanimated';
import Image from '../UtilityComponents/UniversalImage';
import CustomAlert from '../Hooks/Alert/Alert';
import styles from './style';
import { Ionicons } from '@expo/vector-icons'; // Example icon library

interface CommentsProps {
  uploaderUsername: string | null;
  mongoId: string | null; // Add mongoId prop
  isVisible: boolean;
  isBlocked: boolean;
  toggleModal: () => void;
}

interface Comment {
  userId: string;
  text: string;
  _id: string;
  createdAt: string;
  profilePicture: string; // Add this line
}

type NavigateToUserViewOtherFunction = (userId: string) => void;

interface CommentItemProps {
  comment: Comment;
  navigateToUserViewOther: NavigateToUserViewOtherFunction;
  handleDelete: () => void;
}

import { API_URL } from '@env';

const CommentItem: React.FC<CommentItemProps> = ({ comment, navigateToUserViewOther, handleDelete }) => {
  const [imageUri, setImageUri] = useState<string>(`https://storage.googleapis.com/clipdle-profile-pics/${comment.userId}_thumbnail.jpeg`);

  const { userProfile } = useAuth();

  return (
    <View style={styles.commentContainer}>
      <TouchableOpacity onPress={() => navigateToUserViewOther(comment.userId)}>
        <Image
          source={{ uri: imageUri }}
          style={styles.commentAvatar}
          onError={() => setImageUri('https://storage.googleapis.com/clipdle-profile-pics/Default_pfp.jpeg')}
        />
      </TouchableOpacity>
      <View key={comment._id} style={styles.commentContent}>
        <View style={styles.commentTextContainer}>
          <Text style={styles.userIdText}>{comment.userId}</Text>
          <Text style={styles.commentText}>{comment.text}</Text>
        </View>
        {comment.userId === userProfile?.username && (
          <TouchableOpacity onPress={handleDelete} style={styles.deleteButton}>
            <Ionicons name="trash-outline" size={20} color="red" />
          </TouchableOpacity>
        )}
      </View>
    </View>
  );
};

const Comments: React.FC<CommentsProps> = ({ uploaderUsername, mongoId, isVisible, isBlocked, toggleModal }) => {

  const [commentText, setCommentText] = useState('');
  const [comments, setComments] = useState<Comment[]>([]);
  const { userProfile, getToken } = useAuth();
  const [loading, setLoading] = useState(false);
  const [isSelfBlocked, setIsSelfBlocked] = useState(false);
  const [alertVisible, setAlertVisible] = useState(false);
  const [deletionAlertVisible, setDeletionAlertVisible] = useState(false);
  const [alertTitle, setAlertTitle] = useState('');
  const [alertMessage, setAlertMessage] = useState('');

  const navigation = useNavigation<StackNavigationProp<StackParamList>>();

  // Fetch comments when the modal becomes visible
  useEffect(() => {
    if (isVisible) {
      checkIsBlocked();
      fetchComments();
    }
  }, [isVisible]);

  const handleDelete = async (_id: string, userId: string) => {

    try {
      await axios.delete(`${API_URL}/videos/${mongoId}/comments/${_id}`, 
        {
          data: { userId },
          withCredentials: true,
        }
      );
      // Remove the comment from local state
      setComments(comments.filter(comment => comment._id !== _id));
    } catch (error) {
      console.error('Failed to delete comment:', error);
      setAlertTitle('Delete Error');
      setAlertMessage('Failed to delete the comment. Please try again.');
      setAlertVisible(true);
    }

  };

  const navigateToUserViewOther = async (userId: string) => {
    
    try {
      setLoading(true); // If you have a loading state
      const response = await axios.get(`${API_URL}/users/find/${userId}`,
        {
          withCredentials: true,
        }
      );
      const userProfile = response.data;

      navigation.navigate('UserView', {
        username: userProfile.username,
      });
      
    } catch (error) {
      setAlertTitle('Navigation Error');
      setAlertMessage('Error: Unable to load user profile.');
      setAlertVisible(true);
    } finally {
      setLoading(false); // If you have a loading state
    }
  };

  // Function to fetch comments from backend
  const fetchComments = async () => {
    try {
      const response = await axios.get(`${API_URL}/videos/${mongoId}/comments`,
        {
          withCredentials: true,
        }
      );
      const data = await response.data;
      setComments(data);
    } catch (error) {
      console.error('Failed to fetch comments:', error);
    }
  };

  const checkIsBlocked = async () => {
    try {
      const response = await axios.get(`${API_URL}/users/${uploaderUsername}/blocked`, 
      {
        withCredentials: true
      })

      if(response.data && response.data.length > 0 && response.data.blocked.includes(userProfile?.username)){
        setIsSelfBlocked(true);
      } else {
        
      }
    } catch (error: any) {
      
    }
  }

  // Function to post a new comment to backend
  const postComment = async () => {
    if(isSelfBlocked){
      return;
    }
    
    try {
      const response = await axios.post(
        `${API_URL}/videos/${mongoId}/comments`,
        {
          userId: userProfile?.username,
          text: commentText,
        },
        {
          withCredentials: true,
        }
      );
      const data = await response.data;
      setComments([...comments, data]);
    } catch (error) {
      console.error('Failed to post comment:', error);
    }
  };
  const handleCommentSubmit = () => {
    if (commentText.trim()) {
      if (commentText.length > 2000) {
        // Alert the user that the comment is too long
        setAlertTitle('Comment Error');
        setAlertMessage('Comments cannot exceed 2000 characters.');
        setAlertVisible(true);
      } else {
        postComment();
        setCommentText('');
      }
    }
  };

  // Shared value for translation Y
  const translateY = useSharedValue(0);

  // Gesture handler
  const gestureHandler = useAnimatedGestureHandler({
    onStart: (_, ctx: any) => {
      ctx.startY = translateY.value;
    },
    onActive: (event, ctx: any) => {
      translateY.value = ctx.startY + event.translationY;
    },
    onEnd: (_) => {
      if (translateY.value > 100) {
        runOnJS(toggleModal)(); // Wrap toggleModal with runOnJS
      } else {
        translateY.value = withSpring(0);
      }
    },
  });
  

  // Animated style
  const animatedStyle = useAnimatedStyle(() => {
    return {
      transform: [{ translateY: translateY.value }],
    };
  }, []); // Add dependencies inside the array if any

  return (
    <GestureHandlerRootView style={{ flex: 1 }}>
    <View style={styles.centeredView}>
      <Modal
        animationType="slide"
        transparent={true}
        visible={isVisible}
        onRequestClose={toggleModal} // Use the toggleModal prop to hide the modal
      >
        
         <KeyboardAvoidingView
            style={styles.centeredView}
            behavior={Platform.OS === "ios" ? "padding" : "height"}
          >
            
          <PanGestureHandler onGestureEvent={gestureHandler}>
            
            <Animated.View style={[styles.modalView, animatedStyle]}>

                <ScrollView style={{width: '100%'}}>
                {comments.length > 0 ? (
                  comments.map((comment) => (
                    <CommentItem 
                      key={comment._id} 
                      comment={comment} 
                      navigateToUserViewOther={navigateToUserViewOther}
                      handleDelete={() => handleDelete(comment._id, comment.userId)}
                    />
                  ))              
                ) : (
                  <Text style={styles.modalText}>No comments yet...</Text>
                )}

                </ScrollView>
                <View style={styles.textInputContainer}>
                  <TextInput
                    style={styles.textInput}
                    placeholder="Add a comment..."
                    multiline
                    value={commentText}
                    placeholderTextColor={'white'}
                    onChangeText={setCommentText}
                  />
                  <TouchableOpacity
                    style={styles.buttonSubmit}
                    onPress={handleCommentSubmit}
                  >
                    <Text style={styles.textStyle}>Post</Text>
                  </TouchableOpacity>
                </View>

                <View style={styles.closeButtonContainer}>
                    <TouchableOpacity
                    style={styles.closeButton}
                    onPress={toggleModal}
                    >
                    <Text style={styles.closeButtonText}>X</Text>
                    </TouchableOpacity>
                </View>
              
            </Animated.View>
          </PanGestureHandler>
          </KeyboardAvoidingView>
      </Modal>
      <CustomAlert
          isVisible={alertVisible}
          title={alertTitle}
          message={alertMessage}
          buttons={[
              {
                  text: 'OK',
                  onPress: () => {
                      setAlertVisible(false);
                  },
              },
          ]}
          onClose={() => {
              setAlertVisible(false);
          }}
      />

      <CustomAlert
          isVisible={deletionAlertVisible}
          title={alertTitle}
          message={alertMessage}
          buttons={[
              {
                  text: 'DELETE',
                  onPress: () => {
                      setDeletionAlertVisible(false);
                  },
              },
              {
                  text: 'CANCEL',
                  onPress: () => {
                    setDeletionAlertVisible(false);
                  },
              },
          ]}
          onClose={() => {
            setDeletionAlertVisible(false);
          }}
      />
    </View>
    </GestureHandlerRootView>
  );
};



export default Comments;