import React, { useState } from 'react';

export interface AlertButton {
    text: string;
    onPress: () => void;
    style?: 'default' | 'cancel' | 'destructive'; // Adjust according to your needs
}

export interface AlertDetails {
    title: string;
    message: string;
    buttons: AlertButton[]; // Use the AlertButton interface here
    // Add other properties if necessary
}

export const useCustomAlert = () => {
    const [alertProps, setAlertProps] = useState({
        isVisible: false,
        title: '',
        message: '',
        buttons: [{ text: "OK", onPress: () => hideCustomAlert() }],
        onClose: () => hideCustomAlert(),
    });

    const showCustomAlert = (title: string, message: string, buttons = [{ text: "OK", onPress: () => hideCustomAlert() }]) => {
        setAlertProps({
            isVisible: true,
            title: title,
            message: message,
            buttons: buttons,
            onClose: () => hideCustomAlert(),
        });
    };

    const hideCustomAlert = () => {
        setAlertProps(prevProps => ({ ...prevProps, isVisible: false }));
    };

    return {
        showCustomAlert,
        hideCustomAlert,
        alertProps,
        setAlertProps,
    };
};
