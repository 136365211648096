import React, { useState } from 'react';
import { View, Text, TouchableOpacity, StyleSheet, Alert, ActivityIndicator, TextInput, Image } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { LoginStackParamList } from '../../navigators/LoginNavigator';
const clipdleGif = require('../../assets/gifs/clipdle-thinking.gif');
import CustomAlert from '../Hooks/Alert/Alert';
import styles from './style';

// Custom Hooks
import { useAuthentication } from '../../hooks/user/useAuthentication';

const LoginScreen = () => {
  const navigation = useNavigation<StackNavigationProp<LoginStackParamList>>();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const { loading, handleLogin, alertVisible, setAlertVisible, alertTitle, alertMessage } = useAuthentication();

  const navigateToSignUp = () => {
    navigation.navigate('SignUpScreen');
  };

  return (
    <View style={styles.container}>

      <CustomAlert
        title={alertTitle}
        message={alertMessage}
        isVisible={alertVisible}
        onClose={() => setAlertVisible(false)} 
        buttons={[{
          text: 'OK',
          onPress: () => setAlertVisible(false)
        }]}      
      />
      
      <View style={styles.titleContainer}>
        <Text style={styles.title}>Clipdle</Text>
        <Image 
          source={clipdleGif} // Replace with your GIF's path
          style={styles.gifStyle}
        />  
      </View>
      
      <Text style={styles.slogan}>Not Just Watching – Question, Answer, and Connect!</Text>

      <TextInput
        style={styles.input}
        onChangeText={setEmail}
        value={email}
        placeholder="Email"
        placeholderTextColor={'black'}
        keyboardType="email-address"
        autoCapitalize="none"
      />
      <TextInput
        style={styles.input}
        onChangeText={setPassword}
        value={password}
        placeholder="Password"
        placeholderTextColor={'black'}
        secureTextEntry
      />

      <TouchableOpacity
        style={styles.loginButton}
        onPress={() => handleLogin(email, password)}
        disabled={loading}
      >
        {loading ? (
          <ActivityIndicator size="small" color="#FFFFFF" />
        ) : (
          <Text style={styles.buttonText}>Log In</Text>
        )}
      </TouchableOpacity>
          
      <Text style={styles.forgotPasswordText} onPress={() => navigation.navigate('ForgotPassword')}>Forgot your password?</Text>

      <Text style={styles.signUpText}>
        Don't have an account?{' '}
        <Text style={styles.signUpLink} onPress={navigateToSignUp}>
          Sign Up
        </Text>
      </Text>
    </View>
  );
};



export default LoginScreen;