import React, { useState, useRef, useEffect, useCallback } from 'react';
import { View, Text, TouchableOpacity, Animated, Alert } from 'react-native';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faThumbsUp, faThumbsDown, faCommentDots, faShareSquare, faTrash, faArrowAltCircleLeft, faFlag } from '@fortawesome/free-solid-svg-icons';
import Comments from '../Comments/Comments';
import { useAuth } from '../../context/AuthContext';
import axios from 'axios';
import { useFocusEffect } from '@react-navigation/native';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { StackParamList } from '../../navigators/navigationTypes';
import ReportModal from '../ReportModal/ReportModal';
import Image from '../UtilityComponents/UniversalImage';
import CustomAlert from '../Hooks/Alert/Alert';
import { useRefetch } from '../../context/RefetchContext';
import AnswersModal from '../AnswersModal/AnswersModal';
import styles from './style';
import { getAuth } from 'firebase/auth';

import { WatchedVideoData } from '../Quiz/QuizTypes'; // Use WatchedVideoData
import { useCustomAlert } from '../../hooks/useCustomAlert';
import { API_URL } from '@env';

type QuizOverlayProps = {
  onAnswerSelect: (answer: string, index: number, isCorrect: boolean) => void; // Updated
  correctAnswer: number | null;
  question: string | null;
  answers: string[];
  mongoId: string | null;
  GCSId: string | null;
  isBlocked: boolean | null;
  uploaderUsername: string | null;
  isFromProfile: boolean | null;
  onVideoInteraction: (updates: Partial<WatchedVideoData>) => void; // Updated type
};

const QuizOverlay: React.FC<QuizOverlayProps> = ({ mongoId, GCSId, onAnswerSelect, correctAnswer, question, isBlocked, answers, uploaderUsername, isFromProfile, onVideoInteraction }) => {
  const [selectedAnswer, setSelectedAnswer] = useState<string | null>(null);
  const [showOptions, setShowOptions] = useState(false);
  const [showComments, setShowComments] = useState(false);
  const {userProfile, getToken} = useAuth();
  const [isLiked, setIsLiked] = useState(false);
  const [isDisliked, setIsDisliked] = useState(false);
  const [isFollowingUploader, setIsFollowingUploader] = useState(false);
  const [reportModalVisible, setReportModalVisible] = useState(false);
  const [isReported, setIsReported] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);

  const { showCustomAlert, hideCustomAlert, alertProps, setAlertProps } = useCustomAlert();
  const { triggerRefetch } = useRefetch();

  const profilePicUrl = `https://storage.googleapis.com/clipdle-profile-pics/${uploaderUsername}_thumbnail.jpeg`;

  const handleOptionButtonClick = () => {
    setModalVisible(true);
  };

  const handleAnswerClick = (answer: string, index: number, isCorrect: boolean) => {
    setSelectedAnswer(answer);
    // Update watched video data via onVideoInteraction
    onVideoInteraction({
      didAnswer: true,
      answeredCorrectly: isCorrect,
      userAnswerIndex: index
    });

    // Call the original onAnswerSelect to maintain old logic if needed
    // If you no longer need it in old form, you can remove or refactor it.
    onAnswerSelect(answer, index, isCorrect);
  };


  const toggleCommentsModal = () => {
    setShowComments(!showComments);
  };

  const thumbsUpScale = useRef(new Animated.Value(1)).current;
  const thumbsDownScale = useRef(new Animated.Value(1)).current;

  const [thumbsUpColor, setThumbsUpColor] = useState(isLiked ? 'gold' : 'white');
  const [thumbsDownColor, setThumbsDownColor] = useState(isDisliked ? 'red' : 'white');

  const navigation = useNavigation<StackNavigationProp<StackParamList>>();

  const showAlert = (type: string, title: string, message: string) => {
    let buttons;
    switch (type) {
      case 'copyURL':
        buttons = [{ text: "OK", onPress: () => hideCustomAlert() }];
        break;
      case 'deleteVideo':
        buttons = [
          { text: "Cancel", onPress: () => hideCustomAlert() },
          { text: "OK", onPress: () => deleteVideo() }
        ];
        break;
      default:
        buttons = [{ text: "OK", onPress: () => hideCustomAlert() }];
    }
    showCustomAlert(title, message, buttons);
  };

  const navigateToUserViewOther = async (userId: string | null) => {

    if (!userProfile?.username) {
      // or if (!isAuthenticated)
      onVideoInteraction({}); // (this triggers navigateLogin() in PublicVideoScreen)
      return;
    }

    try {

      const response = await axios.get(`${API_URL}/users/find/${userId}`,
        {
          withCredentials: true,
        }
      );
      const userProfile = response.data;

      navigation.navigate('UserView', {
        username: userProfile.username,
      });
    } catch (error) {
      Alert.alert('Error', 'Unable to load user profile.');
    }
  };

  const animateThumbsUp = () => {
    Animated.sequence([
      Animated.timing(thumbsUpScale, {
        toValue: 1.2,
        duration: 100,
        useNativeDriver: false
      }),
      Animated.timing(thumbsUpScale, {
        toValue: 1,
        duration: 100,
        useNativeDriver: false
      })
    ]).start(() => {
      setThumbsUpColor('gold');
      setThumbsDownColor('white');
    });
  };

  const animateThumbsDown = () => {
    Animated.sequence([
      Animated.timing(thumbsUpScale, {
        toValue: 1.2,
        duration: 100,
        useNativeDriver: false
      }),
      Animated.timing(thumbsUpScale, {
        toValue: 1,
        duration: 100,
        useNativeDriver: false
      })
    ]).start(() => {
      setThumbsUpColor('white');
      setThumbsDownColor('red');
    });
  };

  useFocusEffect(
    useCallback(() => {
      return () => {
        setShowComments(false);
      };
    }, [])
  );

  const handleLike = async () => {
    if(!mongoId) return;

    if (!userProfile?.username) {
      // or if (!isAuthenticated)
      onVideoInteraction({}); // (this triggers navigateLogin() in PublicVideoScreen)
      return;
    }

    try {
      animateThumbsUp();

      // This route increases the likes of the video, increments the uploading users monthlyLikes, 
      // and adds the video to the liking users likedVideos array
      await axios.patch(`${API_URL}/videos/${mongoId}/${uploaderUsername}/${userProfile?.username}/likes/increase`,
        {}, 
        {
          withCredentials: true
        }
      );
      
      // To track liked status of the video for the user
      setIsLiked(true);

      if(isDisliked)
      {
        handleUndislike();
      }

    } catch (error) {
      console.error('Error liking video:', error);
    }
  };

  const handleDislike = async () => {
    if(!mongoId) return;

    if (!userProfile?.username) {
      // or if (!isAuthenticated)
      onVideoInteraction({}); // (this triggers navigateLogin() in PublicVideoScreen)
      return;
    }

    try {
      animateThumbsDown();

      // This route decreases the likes of the video, decrements the uploading users monthlyLikes, 
      // and removes the video to the liking users likedVideos array
      await axios.patch(`${API_URL}/videos/${mongoId}/${userProfile?.username}/dislikes/increase`, 
        {},
        {
          withCredentials: true,
        }
      );

      setIsDisliked(true);

      onVideoInteraction({ didDislike: true });
      onVideoInteraction({ didLike: false });

      // Make sure user doesn't like the video if they dislike it
      if(isLiked)
      {
        handleUnlike();
      }

    } catch (error) {
      console.error('Error disliking video:', error);
    }
  }

  const handleUndislike = async () => {
    if(!mongoId) return;

    if (!userProfile?.username) {
      // or if (!isAuthenticated)
      onVideoInteraction({}); // (this triggers navigateLogin() in PublicVideoScreen)
      return;
    }

    try {

      // This route decreases the likes of the video, decrements the uploading users monthlyLikes, 
      // and removes the video to the liking users likedVideos array
      await axios.patch(`${API_URL}/videos/${mongoId}/${userProfile?.username}/dislikes/decrease`, {},
        {
          withCredentials: true,
        });

      setIsDisliked(false);

      onVideoInteraction({ didDislike: false });

      setThumbsDownColor('white');

    } catch (error) {
      console.error('Error disliking video:', error);
    }
  }

  const handleUnlike = async () => {
    if(!mongoId) return;

    if (!userProfile?.username) {
      // or if (!isAuthenticated)
      onVideoInteraction({}); // (this triggers navigateLogin() in PublicVideoScreen)
      return;
    }

    try {

      // This route decreases the likes of the video, decrements the uploading users monthlyLikes, 
      // and removes the video to the liking users likedVideos array
      await axios.patch(`${API_URL}/videos/${mongoId}/${uploaderUsername}/${userProfile?.username}/likes/decrease`, {},
        {
          withCredentials: true,
        });
      
      setIsLiked(false);

      onVideoInteraction({ didLike: false });

      setThumbsUpColor('white');

    } catch (error) {
      console.error('Error unliking video:', error);
    }
  };

  const handleReportCopyright = async () => {
    if(!mongoId) return;

    if (!userProfile?.username) {
      // or if (!isAuthenticated)
      onVideoInteraction({}); // (this triggers navigateLogin() in PublicVideoScreen)
      return;
    }

    try {

      await axios.patch(`${API_URL}/videos/${mongoId}/reportCopyright`, {},
        {
          withCredentials: true,
        });
    } catch (error) {
      console.error('Error reporting video:', error);
    }
  };

  const handleReportOther = async () => {
    if(!mongoId) return;

    if (!userProfile?.username) {
      // or if (!isAuthenticated)
      onVideoInteraction({}); // (this triggers navigateLogin() in PublicVideoScreen)
      return;
    }

    try {
      await axios.patch(`${API_URL}/videos/${mongoId}/reportOther`, {},
        {
          withCredentials: true,
        });
    } catch (error) {
      console.error('Error reporting video:', error);
    }
  };

  const copyToClipboard = () => {
    const URL = `https://clipdle.tv/video/${GCSId}/${GCSId}/${uploaderUsername}`;
    if (navigator.clipboard) {
      navigator.clipboard.writeText(URL).then(() => {
        showAlert('copyURL', 'Copied', 'URL has been copied to clipboard');
      }).catch(err => {
        console.error('Could not copy text: ', err);
      });
    } else {
      const textArea = document.createElement("textarea");
      textArea.value = URL;
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      try {
        document.execCommand('copy');
        showAlert('copyURL', 'Copied', 'URL has been copied to clipboard');
      } catch (err) {
        console.error('Unable to copy: ', err);
      }
      document.body.removeChild(textArea);
    }
  };

  const backToPreviousScreen = () => {
    navigation.goBack();
  }

  const deleteVideo = async () => {
    if(!mongoId) return;

    if (!userProfile?.username) {
      // or if (!isAuthenticated)
      onVideoInteraction({}); // (this triggers navigateLogin() in PublicVideoScreen)
      return;
    }

    try {;
      await axios.delete(`${API_URL}/videos/${mongoId}`, {
        withCredentials: true,
      });
      navigation.goBack();
    } catch (error) {
      console.error('Error deleting video:', error);
    }

    triggerRefetch();
    navigation.goBack();
  };

  const handleDeleteVideo = () => {
    showAlert('deleteVideo', 'Confirm Delete', 'Are you sure you want to delete this video?');
  };

  useEffect(() => {
    const fetchStatuses = async () => {
      if (userProfile?.username && mongoId) {

        if (!userProfile?.username) {
          // or if (!isAuthenticated)
          onVideoInteraction({}); // (this triggers navigateLogin() in PublicVideoScreen)
          return;
        }

        try {

          const likeResponse = await axios.get(`${API_URL}/users/${userProfile.username}/likes/${mongoId}`,
            {
              withCredentials: true
            }
          );
          setIsLiked(likeResponse.data.isLiked);
          setThumbsUpColor(likeResponse.data.isLiked ? 'gold' : 'white');
  
          const dislikeResponse = await axios.get(`${API_URL}/users/${userProfile.username}/dislikes/${mongoId}`,
            {
              withCredentials: true,
            }
          );
          setIsDisliked(dislikeResponse.data.isDisliked);
          setThumbsDownColor(dislikeResponse.data.isDisliked ? 'red' : 'white');
        } catch (error) {
          console.error('Error fetching like/dislike status:', error);
          setThumbsUpColor('white');
          setThumbsDownColor('white');
        }
      }
    };
    fetchStatuses();
  }, [mongoId, userProfile?.username]);

  useEffect(() => {
    if (userProfile && uploaderUsername) {
      const followingUserIdList = userProfile?.followingUserIds || [];
      setIsFollowingUploader(followingUserIdList.includes(uploaderUsername));
    }
  }, [userProfile, uploaderUsername]);

  return ( 
    <>
      {isFromProfile && (
        <View style={styles.headerContainer}>
          <TouchableOpacity onPress={() => backToPreviousScreen()} style={styles.backButton}>
            <FontAwesomeIcon icon={faArrowAltCircleLeft} size={24} color="white"/>
          </TouchableOpacity>

          {isFromProfile && uploaderUsername === userProfile?.username && (
            <TouchableOpacity onPress={handleDeleteVideo} style={styles.deleteButton}>
              <FontAwesomeIcon icon={faTrash} color="white" size={24} />
            </TouchableOpacity>
          )}
        </View>
      )}
      <View style={styles.overlayContainer}>
        <CustomAlert {...alertProps} />
                        
        <View style={styles.profileContainer}>
          <TouchableOpacity onPress={() => navigateToUserViewOther(uploaderUsername)} style={styles.profileTouchable}>
            <Image
              source={{ uri: profilePicUrl }}
              style={styles.profilePic}
            />
            <Text style={styles.username}>{uploaderUsername}</Text>
          </TouchableOpacity>
        </View>

        <TouchableOpacity 
          testID='optionButton'
          style={styles.questionOverlay} 
          onPress={handleOptionButtonClick}
        >
          <Text style={styles.questionText}>{question}</Text>
        </TouchableOpacity>

        <AnswersModal
          isVisible={modalVisible}
          onClose={() => setModalVisible(false)}
          question={question || ''}
          answers={answers}
          mongoId={mongoId}
          videoId={GCSId}
          correctAnswer={correctAnswer ?? -1}
          // Now this callback receives answer, index, and isCorrect
          onAnswerSelect={(answer: string, index: number, isCorrect: boolean) => {
            handleAnswerClick(answer, index, isCorrect);
          }}
        />

        {showOptions && (
        <View style={styles.optionsContainer}>
            {answers.map((option, index) => (
            <TouchableOpacity 
                key={option}
                style={[
                styles.optionButton, 
                selectedAnswer === option && index === correctAnswer ? styles.correct : 
                selectedAnswer === option ? styles.incorrect : {}
                ]}
                onPress={() => handleAnswerClick(option, index, index === correctAnswer)}
            >
                <Text style={styles.optionText}>{option}</Text>
            </TouchableOpacity>
            ))}
        </View>
        )}

        {showComments && <Comments uploaderUsername={uploaderUsername} mongoId={mongoId} isVisible={showComments} isBlocked={isBlocked ?? false} toggleModal={toggleCommentsModal} />}

        <View style={styles.actionButtons}>
          <TouchableOpacity
            style={[styles.iconContainer, isLiked ? styles.disabledButton : {}]}
            onPress={isLiked ? handleUnlike : handleLike}
            testID='like-button'
          >
            <Animated.View style={{ transform: [{ scaleX: thumbsUpScale }, { scaleY: thumbsUpScale }] }}>
              <FontAwesomeIcon icon={faThumbsUp} color={thumbsUpColor as any} size={36} />
            </Animated.View>
          </TouchableOpacity>

          <TouchableOpacity
            style={[styles.iconContainer, isDisliked ? styles.disabledButton : {}]}
            onPress={isDisliked ? handleUndislike : handleDislike}
            testID='dislike-button'
          >
            <Animated.View style={{ transform: [{ scaleX: thumbsDownScale }, { scaleY: thumbsDownScale }] }}>
              <FontAwesomeIcon icon={faThumbsDown} color={thumbsDownColor as any} size={36} />
            </Animated.View>
          </TouchableOpacity>

          <TouchableOpacity style={styles.iconContainer} onPress={toggleCommentsModal}>
            <FontAwesomeIcon icon={faCommentDots} color="white" size={36} />
          </TouchableOpacity>

          <TouchableOpacity style={styles.iconContainer} onPress={copyToClipboard}>
            <FontAwesomeIcon icon={faShareSquare} color="white" size={36} />
          </TouchableOpacity>

          <TouchableOpacity
            style={styles.iconContainer}
            onPress={() => setReportModalVisible(true)}
          >
            <FontAwesomeIcon icon={faFlag} color="white" size={36} />
          </TouchableOpacity>
        </View>

        <ReportModal
          isVisible={reportModalVisible}
          onClose={() => setReportModalVisible(false)}
          onReportCopyright={handleReportCopyright}
          onReportOther={handleReportOther}
          username={uploaderUsername}
        />
      </View>
    </>
  );
};

export default QuizOverlay;
