// UserSearchNavigator.tsx
import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import UserSearch from '../components/UserSearch/UserSearch';
import UserViewOther from '../components/UserViewOther/UserViewOther';
import UserViewSelf from '../components/UserViewSelf/UserViewSelf';
import QuizViewerProfile from '../components/QuizViewerProfile/QuizViewerProfile';
import Quiz from '../components/Quiz/Quiz';
import { StackParamList } from './navigationTypes';
import { View } from 'react-native';
import UserView from '../components/UserView/UserView';
import { NavigationContainer, NavigationContainerRef } from '@react-navigation/native';

// Export a ref to control this child navigator
export const userStartStackRef = React.createRef<NavigationContainerRef<any>>(); 

function UserStartNavigator() {

  const Stack = createStackNavigator<StackParamList>();

  return (
    <View style={{height: '100%', backgroundColor:'black'}}>
      <NavigationContainer
        independent={true}
        ref={userStartStackRef}
      >
        <Stack.Navigator screenOptions={{headerShown: false}} initialRouteName="UserViewSelf">
          <Stack.Screen name="Quiz" component={Quiz} />
          <Stack.Screen name="UserSearch" component={UserSearch} />
          <Stack.Screen name="UserViewOther" component={UserViewOther} />
          <Stack.Screen name="UserViewSelf" component={UserViewSelf} />
          <Stack.Screen name="UserView" component={UserView} />
          <Stack.Screen name="QuizViewerProfile" component={QuizViewerProfile} />
          {/* ... other screens ... */}
        </Stack.Navigator>
      </NavigationContainer>
    </View>
  );
}

export default UserStartNavigator;