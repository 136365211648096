
import React, { useState, useEffect } from 'react';
import { View, Text, StyleSheet, FlatList, TouchableOpacity, ListRenderItemInfo, Dimensions } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { RouteProp } from '@react-navigation/native';
import { StackParamList } from '../../navigators/navigationTypes';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faArrowAltCircleLeft, faHandDots, faListDots } from '@fortawesome/free-solid-svg-icons';
import { StackNavigationProp } from '@react-navigation/stack';
import { useAuth } from '../../context/AuthContext';
import axios, {AxiosError} from 'axios';
import UsersListModal from '../UsersListModal/UsersListModal';
import { getAuth } from 'firebase/auth';

import Image from '../UtilityComponents/UniversalImage';
import UserActionModal from '../UserActionModal/UserActionModal'; // Import the modal
import AsyncStorage from '@react-native-async-storage/async-storage';

// Define the type for the props expected by UserViewOther component
type UserViewOtherProps = {
  route: RouteProp<StackParamList, 'UserViewOther'>;
};

import { API_URL } from '@env';

const UserViewOther: React.FC<UserViewOtherProps> = ({ route }) => {

  const { userProfile } = route.params;
  const { userProfile: ownUserProfile, updateUserFollowing, updateBlockedUsers, getToken } = useAuth();
  const [isFollowing, setIsFollowing] = useState(false);
  const isOwnProfile = (userProfile.username === ownUserProfile?.username);

  const [following, setFollowing] = useState<string[]>([]); // Array of strings
  const [followers, setFollowers] = useState<string[]>([]); // Array of strings

  const [followersCount, setFollowersCount] = useState(0); // State for followers count
  const [followingCount, setFollowingCount] = useState(0); // State for following count
  const [showFollowersModal, setShowFollowersModal] = useState(false); // State to show/hide followers modal
  const [showFollowingModal, setShowFollowingModal] = useState(false); // State to show/hide following modal
  const [showUserActionModal, setShowUserActionModal] = useState(false);
  const [showBlur, setShowBlur] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [isBlockedStatusLoading, setIsBlockedStatusLoading] = useState(false); // State for loading status of isBlocked

  const [isBlocked, setIsBlocked] = useState(false);

  const [uploadedVideos, setUploadedVideos] = useState([]);

  const navigation = useNavigation<StackNavigationProp<StackParamList>>();

  const fetchUploadedVideos = async () => {
    setIsRefreshing(true); // Start refreshing
    try {
      const response = await axios.get(`${API_URL}/users/${userProfile?.username}/uploadedVideos`,
        {
          withCredentials: true,
        }
      );
      setUploadedVideos(response.data.uploadedVideos);
    } catch (error) {
      console.error("DEBUG: Error fetching uploaded videos for user:", userProfile, error);
      console.error('Error fetching uploaded videos:', error);
    } finally {
      setIsRefreshing(false); // Stop refreshing
    }
  };

  // Function to handle opening the user action modal
  const openUserActionModal = () => {
    setShowUserActionModal(true);
  };

  const fetchCountsAndLists = async () => {
    try {
      // Fetch followers and following counts
      const followersCountResponse = await axios.get(`${API_URL}/users/${userProfile.username}/followersCount`,
        {
          withCredentials: true
        }
      );
      const followingCountResponse = await axios.get(`${API_URL}/users/${userProfile.username}/followingCount`,
        {
          withCredentials: true
        }
      );

      setFollowersCount(followersCountResponse.data.followers);
      setFollowingCount(followingCountResponse.data.following);

      // Fetch followers and following lists
      const followersResponse = await axios.get(`${API_URL}/users/${userProfile.username}/followers`,
        {
          withCredentials: true
        }
      );
      const followingResponse = await axios.get(`${API_URL}/users/${userProfile.username}/following`,
        {
          withCredentials: true
        }
      );
      setFollowers(followersResponse.data.followers);
      setFollowing(followingResponse.data.following);

      
      
    } catch (error) {
      console.error('Error fetching counts and lists:', error);
    }
  };

  const fetchIsUserBlocked = () => {
    setIsBlockedStatusLoading(true);

    let isBlockedLocal = ownUserProfile?.blockedUserIds.includes(userProfile.username);

    if(isBlockedLocal){
      setIsBlocked(isBlockedLocal);
      setShowBlur(true);
    } 

    setIsBlockedStatusLoading(false);
  };

  const blockUser = async () => {
    try {
      const blockResponse = await axios.put(`${API_URL}/users/${ownUserProfile?.username}/block/${userProfile.username}`, 
        {},
        {
          withCredentials: true,
        }
      );
      
      setShowUserActionModal(false);

      // Update the local context using updateBlockedUsers
      if (ownUserProfile && userProfile) {
        updateBlockedUsers([...ownUserProfile.blockedUserIds, userProfile.username]);
        setIsBlocked(true);
        setShowBlur(true);
      }
  
    } catch (error: any) {
      console.error(error.response);
    }
  };

  const handleViewAnyway = () => {
    setShowBlur(false);
  };

  // router.put('/users/:username/block/:otherUsername'
  const unblockUser = async () => {
    try {
      const unblockResponse = await axios.put(`${API_URL}/users/${ownUserProfile?.username}/unblock/${userProfile.username}`, 
        {},
        {
          withCredentials: true,
        }
      );
  
      // Update the local context using updateBlockedUsers
      if (ownUserProfile && userProfile) {
        const updatedBlockedUserIds = ownUserProfile.blockedUserIds.filter(id => id !== userProfile.username);
        updateBlockedUsers(updatedBlockedUserIds);
        setIsBlocked(false);
        setShowBlur(false);
      }
  
    } catch (error: any) {
      console.error(error.response);
    }
  };

  const reportUser = async () => {
    try {
      const reportResponse = await axios.patch(`${API_URL}/users/report/${userProfile.username}`,
        {}, 
        {
          withCredentials: true,
        }
      );
      setShowUserActionModal(false);
    } catch (error: any) {
      console.error(error.response);
    }
  };

  const checkFollowingStatus = async () => {
    if (ownUserProfile?.username && userProfile.username) {
      try {
        const response = await axios.get(
          `${API_URL}/users/${ownUserProfile.username}/isFollowing/${userProfile.username}`, 
          {
            withCredentials: true,
          }
        );
        setIsFollowing(response.data.isFollowing);
      } catch (error) {
        console.error('Error checking following status:', error);
      }
    }
  };

  const fetchUserData = async () => {
    try {
      await fetchCountsAndLists();
      await checkFollowingStatus();
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  };

  useEffect(() => {
    // If either the ownUserProfile or userProfile is not available, return
    // TODO: Show an alert
    if (!ownUserProfile?.username || !userProfile.username) return;

    fetchUploadedVideos();
    fetchUserData();
    fetchIsUserBlocked();
  }, [ownUserProfile?.username, userProfile.username]);

  const toggleFollow = async () => {
    if (isFollowing) {
      await handleUnfollow();
    } else {
      await handleFollow();
    }
  };

  const backToPreviousScreen = () => {
    navigation.goBack();
  }

  const handleFollow = async () => {
    try {
      // Call the backend to follow the user
      const followResponse = await axios.put(
          `${API_URL}/users/${ownUserProfile?.username}/follow/${userProfile.username}`,
          {}, // Empty request body since you don't need to send data
          {
              withCredentials: true, // Ensure credentials like cookies are sent
          }
      );
      
      setIsFollowing(true);
  
      // Increment the following count for the current user
      await axios.patch(`${API_URL}/users/${ownUserProfile?.username}/incrementFollowing`, 
        {},
        {
          withCredentials: true,
        }
      );
  
      // Increment the follower count for the other user
      await axios.patch(`${API_URL}/users/${userProfile.username}/incrementFollowers`, 
        {},
        {
          withCredentials: true,
        }
      );

      // Update local AuthContext
      if (ownUserProfile) {
        const updatedFollowing = [...ownUserProfile.followingUserIds, userProfile.username];
        updateUserFollowing(updatedFollowing);
      }
      
      await fetchUserData();
      // Provide feedback to the user
    } catch (error) {
      console.error('Error following user:', error);
      // Handle the error, e.g., show an alert to the user
    }
  };
  
  const handleUnfollow = async () => {
    try {

      // Call the backend to unfollow the user
      const unfollowResponse = await axios.put(
        `${API_URL}/users/${ownUserProfile?.username}/unfollow/${userProfile.username}`, 
        {},
        {
          withCredentials: true,
        }
      );
      
      setIsFollowing(false);

      // Update local AuthContext
      if (ownUserProfile) {
        const updatedFollowing = ownUserProfile.followingUserIds.filter(id => id !== userProfile.username);
        updateUserFollowing(updatedFollowing);
      }
  
      // Decrement the following count for the current user
      await axios.patch(`${API_URL}/users/${ownUserProfile?.username}/decrementFollowing`, 
        {},
        {
          withCredentials: true,
        }
      );
  
      // Decrement the follower count for the other user
      await axios.patch(`${API_URL}/users/${userProfile.username}/decrementFollowers`,
        {}, 
        {
          withCredentials: true,
        }
      );
      
      await fetchUserData();
      // Provide feedback to the user
    } catch (error) {
      console.error('Error unfollowing user:', error);
      // Handle the error, e.g., show an alert to the user
    }
  };

  const navigateToViewer = (videoId: string) => {
    
    navigation.navigate('QuizViewerProfile', {
      videoIds: [videoId], 
      initialVideoId: videoId,  // Add this line
      username: userProfile.username
    });
  }

  // Render followers and following counts
  const renderCounts = () => (
    <View style={styles.countsContainer}>
      <TouchableOpacity onPress={() => setShowFollowersModal(true)}>
        <Text style={{color: 'white'}}>{followersCount} Followers</Text>
      </TouchableOpacity>
      <TouchableOpacity onPress={() => setShowFollowingModal(true)}>
        <Text style={{color:'white'}}>{followingCount} Following</Text>
      </TouchableOpacity>
    </View>
  );

  const renderVideoThumbnail = ({ item }: ListRenderItemInfo<string>) => {
    
    const thumbnailUri = `https://storage.googleapis.com/clipdle_videos_thumbnails/${item}_thumbnail.jpeg`;
    return (
      <TouchableOpacity onPress={() => navigateToViewer(item)} style={styles.clipImageContainer}>
        <Image
          source={{ uri: thumbnailUri }}
          defaultSource={require('../../assets/images/Ex1.jpg')} // Fallback image
          style={styles.clipImage}
        />
      </TouchableOpacity>
    );
  };

  const renderProfileHeader = () => {
    return (
      <View style={styles.profileHeader}>
        <Text style={styles.username}>{userProfile.username}</Text>
        <Text style={styles.bio}>{userProfile.bio}</Text>

        {!isOwnProfile && (
          <View>
            <TouchableOpacity onPress={toggleFollow} disabled={isOwnProfile} style={styles.followButton}>
              <Text style={styles.followButtonText}>{isFollowing ? 'Following' : 'Follow'}</Text>
            </TouchableOpacity>

            <UserActionModal
              isVisible={showUserActionModal}
              onClose={() => setShowUserActionModal(false)}
              isBlocked={isBlocked}
              onBlock={blockUser}
              onUnblock={unblockUser}
              onReport={reportUser}
            />
          </View>
        )}
      </View>
    );
  };
  

  return (
    <View style={styles.container} >
      <View style={styles.header}>
        <TouchableOpacity onPress={backToPreviousScreen} style={styles.backButton}>
          <FontAwesomeIcon icon={faArrowAltCircleLeft} size={24} color="white"/>
        </TouchableOpacity>
        <Image
          source={userProfile.profilePicture ? { uri: userProfile.profilePicture } : require('../../assets/images/avatar.jpg')}
          style={styles.avatar}
        />
        <TouchableOpacity onPress={openUserActionModal} style={styles.userActionStyle}>
          <FontAwesomeIcon icon={faListDots} color='white' size={24}></FontAwesomeIcon>
        </TouchableOpacity>
      </View>

      {renderProfileHeader()}
      {renderCounts()}

      {/* Followers Modal */}
      {followers.length > 0 && (
        <UsersListModal
          users={followers}
          isVisible={showFollowersModal}
          toggleModal={() => setShowFollowersModal(false)}
        />
      )}

      {following.length > 0 && (
        <UsersListModal
          users={following}
          isVisible={showFollowingModal}
          toggleModal={() => setShowFollowingModal(false)}
        />
      )}
      

      {/* FlatList for displaying video thumbnails */}

      <View style={{flex: 1, width: '100%'}}>
        <FlatList
          data={uploadedVideos}
          renderItem={renderVideoThumbnail}
          keyExtractor={item => item}
          numColumns={3}
          style={{paddingTop: 20}}
          contentContainerStyle={{alignItems: 'center'}}
          onRefresh={fetchUploadedVideos}
          refreshing={isRefreshing}
        />
      

        {isBlocked && showBlur && (
          <View style={styles.blurOverlay}>
            <Text style={styles.reportedWarning}>This user has been blocked</Text>
            <TouchableOpacity style={styles.watchAnywayButton} onPress={handleViewAnyway}>
              <Text style={styles.watchAnywayText}>View Anyway</Text>
            </TouchableOpacity>
          </View>
        )}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 0,
    paddingTop: 10,
    color: 'black',
    backgroundColor: 'black',
  },
  header: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 16,
  },
  backButton: {
    padding: 10,
  },
  spacer: {
    width: 24, // Make sure this matches the size of the FontAwesomeIcon to balance the layout
  },
  profileHeader: {
    alignItems: 'center',
    marginBottom: 16,
  },
  fullScreenBlurOverlay: {
    ...StyleSheet.absoluteFillObject, // This will cover the entire area behind the FlatList
    backgroundColor: 'rgba(0, 0, 0, 0.95)', // Adjust opacity for desired blur effect
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 502, // Ensure it is above the FlatList
  },
  actionButton: {
    // Style for the User Actions button
    padding: 10,
    backgroundColor: '#2069e0',
    borderRadius: 5,
    alignSelf: 'center',
    marginTop: 10,
  },
  actionButtonText: {
    color: 'white',
    fontWeight: 'bold',
  },
  avatar: {
    width: 100,
    height: 100,
    borderRadius: 50,
    // Remove marginBottom if not needed
  },
  countsContainer: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    padding: 10,
  },
  userActionStyle: {
    padding: 10,
  },
  username: {
    fontSize: 18,
    fontWeight: 'bold',
    color: 'white',
    marginBottom: 8,
  },
  bio: {
    fontSize: 14,
    color: 'white',
    marginBottom: 8,
  },
  followButton: {
    // Similar styles as in UserView
    backgroundColor: '#1DA1F2',
    padding: 10,
    borderRadius: 5,
    marginTop: 10,
  },
  followButtonText: {
    color: 'white',
    fontWeight: 'bold',
  },
  clipImageContainer: {
    width: 200, // Fixed width for each item, adjust as needed
    maxWidth: Dimensions.get('window').width / 3, // One clip per row
    aspectRatio: 1,
    padding: 2,
  },
  clipImage: {
    width: '100%',
    height: '100%',
    resizeMode: 'cover',
  },
  blurOverlay: {
    ...StyleSheet.absoluteFillObject, // This will cover the entire video
    backgroundColor: 'rgba(0, 0, 0, 0.95)', // Adjust opacity for desired blur effect
    justifyContent: 'center',
    zIndex: 502,
    alignItems: 'center',
  },
  reportedWarning: {
    color: 'red',
    fontSize: 18,
    textAlign: 'center',
    padding: 20,
  },
  watchAnywayButton: {
    backgroundColor: 'blue', // Button color
    paddingHorizontal: 20,
    paddingVertical: 10,
    borderRadius: 5,
  },
  watchAnywayText: {
    color: '#FFFFFF', // Text color
    fontSize: 16,
  },
  // You might want to keep or adjust these styles as needed
});

export default UserViewOther;
