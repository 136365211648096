import React, { useState, useEffect } from 'react';
import { View, Text, TextInput, Button, FlatList, TouchableOpacity, Platform, Alert, ListRenderItemInfo, Dimensions, AlertButton } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { useAuth } from '../../context/AuthContext';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faPencil, faPowerOff, faCheck, faCamera, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { StackParamList } from '../../navigators/navigationTypes';
import UsersListModal from '../UsersListModal/UsersListModal';
import { RouteProp } from '@react-navigation/native';
import { signOut, getAuth, getIdToken } from 'firebase/auth';
import Image from '../UtilityComponents/UniversalImage';
import AsyncStorage from '@react-native-async-storage/async-storage';
import CustomAlert from '../Hooks/Alert/Alert';
import { useRefetch } from '../../context/RefetchContext';
import { globalEvents } from '../../context/GlobalEvents';
import InfoModal from '../InfoModal/InfoModal';
import styles from './style';
import { RootStackParamList } from '../../linkingConfig'; // or wherever

import { useCustomAlert } from '../../hooks/useCustomAlert';

type CustomImagePickerResponse = {
  didCancel?: boolean;
  errorMessage?: string;
  assets?: Array<{
    uri: string;
    fileName?: string;
    type?: string;
  }>;
};

type CustomLaunchImageLibraryOptions = {
  mediaType: 'photo' | 'video';
  maxHeight?: number;
  maxWidth?: number;
};

let ImagePicker: {
  MediaType: any;
  launchImageLibrary: (options: CustomLaunchImageLibraryOptions, callback: (response: CustomImagePickerResponse) => void) => void;
};

if (Platform.OS !== 'web') {
  ImagePicker = require('react-native-image-picker');
} else {
  ImagePicker = {
    MediaType: undefined,
    launchImageLibrary: () => {
      console.warn('Image picker not available on web');
    }
  };
}

// Define the type for the props expected by UserViewOther component
type UserViewOtherProps = {
  route: RouteProp<StackParamList, 'UserViewSelf'>;
};

import { API_URL } from '@env';
import { User } from '../../hooks/user/UserTypes';

const UserViewSelf: React.FC<UserViewOtherProps> = ({ route }) => {

  const [editMode, setEditMode] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [editBioMode, setEditBioMode] = useState(false);
  const [profilePicture, setProfilePicture] = useState(require('../../assets/images/avatar.jpg')); // Default image
  const [loading, setLoading] = useState(true); // Loading state
  const navigation = useNavigation<StackNavigationProp<StackParamList>>();
  const loginNavigation = useNavigation<StackNavigationProp<RootStackParamList>>();
  const { setIsAuthenticated, setUserProfile, userProfile, getToken } = useAuth();
  const [newBio, setNewBio] = useState(userProfile?.bio ?? '');
  const [uploadedVideos, setUploadedVideos] = useState([]);
  const [following, setFollowing] = useState<string[]>([]); // Array of strings
  const [followers, setFollowers] = useState<string[]>([]); // Array of strings
  const [followersCount, setFollowersCount] = useState(0); // State for followers count
  const [followingCount, setFollowingCount] = useState(0); // State for following count
  const [showFollowersModal, setShowFollowersModal] = useState(false); // State to show/hide followers modal
  const [showFollowingModal, setShowFollowingModal] = useState(false); // State to show/hide following modal
  const [isInfoModalVisible, setInfoModalVisible] = useState(false);
  const [didUpload, setDidUpload] = useState(false);
  const { refetchTrigger } = useRefetch(); // Destructure the triggerRefetch function from the context

  const toggleInfoModal = () => setInfoModalVisible(!isInfoModalVisible);

  const { showCustomAlert, hideCustomAlert, alertProps } = useCustomAlert();

  const fetchCountsAndLists = async () => {
    try {
      // Fetch followers and following counts
      const followersCountResponse = await axios.get(
        `${API_URL}/users/${userProfile?.username}/followersCount`,
        {
          withCredentials: true,
        }
      );
      const followingCountResponse = await axios.get(
        `${API_URL}/users/${userProfile?.username}/followingCount`,
        {
          withCredentials: true,
        }
      );

      setFollowersCount(followersCountResponse.data.followers);
      setFollowingCount(followingCountResponse.data.following);

      // Fetch followers and following lists
      const followersResponse = await axios.get(
          `${API_URL}/users/${userProfile?.username}/followers`, 
        {
          withCredentials: true,
        });
      const followingResponse = await axios.get(`${API_URL}/users/${userProfile?.username}/following`,
        {
          withCredentials: true,
        }
      );
      setFollowers(followersResponse.data.followers);
      setFollowing(followingResponse.data.following);

    } catch (error) {
      console.error('Error fetching counts and lists:', error);
    }
  };

  // Ensure fetchUploadedVideos is accessible in the scope where you need it
  const fetchUploadedVideos = async () => {
    setIsRefreshing(true); // Start refreshing
    try {
      const response = await axios.get(
        `${API_URL}/users/${userProfile?.username}/uploadedVideos`, 
        {
          withCredentials: true,
        }
      );
      setUploadedVideos(response.data.uploadedVideos);
    } catch (error) {
      console.error('Error fetching uploaded videos:', error);
    } finally {
      setIsRefreshing(false); // Stop refreshing
    }
  };

  const fetchProfilePicture = async () => {
    try {
      setLoading(true); // Start loading
      const response = await axios.get(`${API_URL}/users/${userProfile?.username}/profile-picture`,
        {
          withCredentials: true,
        }
      );
      
      setProfilePicture({
        uri: response.data.profilePictureUrl + '?cacheBust=' + Date.now(),
      });

      setEditMode(false);
      
    } catch (error) {
      console.error('Error fetching profile picture:', error);
      // If error, keep the default profile picture
    } finally {
      setLoading(false); // Stop loading
    }
  };

  useEffect(() => {
    fetchUploadedVideos();
  }, [refetchTrigger]); // Depend on the refetchTrigger value

  useEffect(() => {
    fetchUploadedVideos();
    fetchCountsAndLists();
  }, [userProfile?.username]);

  useEffect(() => {
    if (userProfile?.username) {
      fetchProfilePicture(); // Only fetch if the username is available
    }
  }, [userProfile?.username, didUpload]); // Depend on the username

  useEffect(() => {
    const handleVideoUploaded = () => {
      fetchUploadedVideos();
    };

    // Listen for the videoUploaded event
    globalEvents.on('videoUploaded', handleVideoUploaded);

    // Cleanup the listener when the component unmounts
    return () => {
      globalEvents.off('videoUploaded', handleVideoUploaded);
    };
  }, []); // This effect runs only once when the component mounts

  const renderCounts = () => (
    <View style={styles.countsContainer}>
      <TouchableOpacity onPress={() => setShowFollowersModal(true)}>
        <Text style={{color: 'white'}}>{followersCount} Followers</Text>
      </TouchableOpacity>
      <TouchableOpacity onPress={() => setShowFollowingModal(true)}>
        <Text style={{color: 'white'}}>{followingCount} Following</Text>
      </TouchableOpacity>
    </View>
  );

  const renderEditProfileButton = () => {
    var buttonColor;
    if(Platform.OS === 'ios'){
      buttonColor="white";
    } else {
      buttonColor="blue";
    }

    const button = editMode ? (
      <Button title="Cancel" color={buttonColor} onPress={cancelEdit} />
    ) : (
      <Button title="Edit Profile" color={buttonColor} onPress={() => setEditMode(true)} />
    );
  
    // Apply the styles.editButton view only on iOS
    if (Platform.OS === 'ios') {
      return <View style={styles.editButton}>{button}</View>;
    } else {
      // Directly return the button for Android
      return button;
    }
  };

  const renderProfileImage = () => {
    const handlePress = editMode ? pickImageAndUpload : undefined;
    return (
      <View style={styles.profileImageContainer}>
        <TouchableOpacity onPress={handlePress} style={styles.imageWrapper}>
          <Image
            source={loading ? require('../../assets/images/avatar.jpg') : profilePicture}
            style={styles.avatar}
          />
          {editMode && (
            <View style={styles.cameraIcon}>
              <FontAwesomeIcon icon={faCamera} size={20} color="#fff" />
            </View>
          )}
        </TouchableOpacity>
      </View>
    );
  };

  const handleLogout = async () => {
    try {
      // 1) Sign out from Firebase
      const Auth = getAuth();
      await signOut(Auth);
  
      // 2) Tell your server to clear the session cookie
      await axios.post(
        `${API_URL}/users/auth/logout`,
        { withCredentials: true }
      );
  
      // 3) Cleanup local state
      setIsAuthenticated(false);
      setUserProfile({} as User);
      AsyncStorage.removeItem('userProfile');
      // const parent = navigation.getParent();        // This might be the tab navigator
      // const root = parent?.getParent();            // This might be the root stack
      navigation.navigate('LoginScreen');
    } catch (error) {
      Alert.alert('Logout Error', 'An error occurred while trying to log out.');
      console.error(error);
    }
  };

  const renderLogoutButton = () => {
    const showLogoutDialog = () => {
      showCustomAlert(
        "Logout",
        "Are you sure you want to logout?",
        [
          { text: "Cancel", onPress: () => hideCustomAlert() },
          { text: "Yes", onPress: handleLogout }
        ]
      );
    };
  
    return (
      <View style={styles.logoutContainer}>
        <TouchableOpacity onPress={showLogoutDialog} style={styles.logoutButton}>
          <FontAwesomeIcon icon={faPowerOff} size={20} color="white"/>
        </TouchableOpacity>
        {/* CustomAlert for Logout Confirmation */}
        <CustomAlert {...alertProps} />
      </View>
    );
  };


  const cancelEdit = () => {
    setEditBioMode(false);
    setEditMode(false);
    setNewBio(userProfile?.bio ?? ''); // Reset the bio to the last saved state
  };

  const renderBioSection = () => {
    return (
      <View style={styles.bioContainer}>
        {editBioMode ? (
          <>
            <TextInput
              style={styles.bioInput}
              onChangeText={setNewBio}
              value={newBio}
              placeholder={userProfile?.bio}
              multiline
            />
            <TouchableOpacity onPress={updateBio}>
              <FontAwesomeIcon icon={faCheck} size={20} color="white" />
            </TouchableOpacity>
          </>
        ) : (
          <>
            <Text style={styles.bio}>{userProfile?.bio}</Text>
            {editMode && (
              <TouchableOpacity onPress={() => setEditBioMode(true)}>
                <FontAwesomeIcon icon={faPencil} size={20} color="white" />
              </TouchableOpacity>
            )}
          </>
        )}
      </View>
    );
  };

  const navigateToViewer = (videoId: string) => {
    if(userProfile){
      navigation.navigate('QuizViewerProfile', {videoIds: [videoId], initialVideoId: videoId, username: userProfile.username});
    }
    
  }

  // Function to render each uploaded video's thumbnail
  const renderVideoThumbnail = ({ item }: ListRenderItemInfo<string>) => {
    
    const thumbnailUri = `https://storage.googleapis.com/clipdle_videos_thumbnails/${item}_thumbnail.jpeg`;
    return (
      <TouchableOpacity onPress={() => navigateToViewer(item)} style={styles.clipImageContainer}>
        <Image
          source={{ uri: thumbnailUri }}
          defaultSource={require('../../assets/images/Ex1.jpg')} // Fallback image
          style={styles.clipImage}
        />
      </TouchableOpacity>
    );
  };

  const updateBio = async () => {
    // Validate the bio
    if (newBio.length > 200) {
      Alert.alert('Error', 'Bio must be less than 200 characters.');
      return;
    }

    try {
      setEditBioMode(false);

      const response = await axios.post(
        `${API_URL}/users/${userProfile?.username}/bio`,
        { bio: newBio },
        {
          withCredentials: true,
        }
      );
      Alert.alert('Bio Updated', 'Your bio has been updated successfully.');

      setEditMode(false); // Exit edit mode
      // Update local user profile state if needed
      if(userProfile){
        setUserProfile({ ...userProfile, bio: newBio });
      }
    } catch (error) {
      Alert.alert('Error', 'There was an issue updating your bio.');
      console.error('Error updating bio:', error);
    }
  };

  
const pickImageAndUpload = () => {
  if (Platform.OS === 'web') {
    // Web-specific code
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/*'; // Accepts all image types
    input.onchange = async (event) => {
      const target = event.target as HTMLInputElement;
      const file = target.files ? target.files[0] : null;
      if (file) {
        const formData  = new FormData();
        formData .append('file', file, file.name);

        try {
          const token = await getToken();
          const uploadResponse = await axios.post(
            `${API_URL}/users/${userProfile?.username}/profile-picture`,
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true,
            }
          );
          const uploadedImageUrl = uploadResponse.data;

          setDidUpload(!didUpload); // Toggle the didUpload state to trigger a re-render
        } catch (error: unknown) {
          if (axios.isAxiosError(error)) {
            console.error('Axios error:', error.message);
            if (error.response) {
              console.error('Response data:', error.response.data);
              console.error('Response status:', error.response.status);
              console.error('Response headers:', error.response.headers);
            }
          } else {
            console.error('Non-Axios error:', error);
          }
        }
      }
    };
    input.click();
  } else {
    const options = {
      mediaType: ImagePicker.MediaType.Photo,
      maxHeight: 1000,
    };

    ImagePicker.launchImageLibrary(options, async (response) => {
      if (response.errorMessage) {
        console.error('ImagePicker Error: ', response.errorMessage);
      } else if (response.assets && response.assets[0].uri) {
        const asset = response.assets[0];
        const source = { uri: asset.uri };

        const fileName = asset.fileName ?? 'unknown';
        const fileType = asset.type ?? 'image/jpeg'; // Default to 'image/jpeg' if type is undefined
        const fileUri = asset.uri;

        try {
          const responseBlob = await fetch(fileUri);
          const blob = await responseBlob.blob();

          const data = new FormData();
          data.append('file', blob, fileName);

          const token = await getToken();
          const uploadResponse = await axios.post(
            `${API_URL}/users/${userProfile?.username}/profile-picture`,
            data,
            {
              headers: {
                Authorization: `Bearer ${token}`, // Include the ID token in the Authorization header
                'Content-Type': 'multipart/form-data',
              },
            }
          );
          const uploadedImageUrl = uploadResponse.data;

          setDidUpload(!didUpload); // Toggle the didUpload state to trigger a re-render
        } catch (error: unknown) {
          if (axios.isAxiosError(error)) {
            console.error('Axios error:', error.message);
            if (error.response) {
              console.error('Response data:', error.response.data);
              console.error('Response status:', error.response.status);
              console.error('Response headers:', error.response.headers);
            }
          } else {
            console.error('Non-Axios error:', error);
          }
        }
      } else {
        console.error('No image selected');
      }
    });
  }
};
  
  return (
    <View style={styles.container} >
      <View style={styles.header}>
        {renderProfileImage()}
        {renderLogoutButton()}
        <TouchableOpacity onPress={toggleInfoModal} style={styles.infoButton}>
          <FontAwesomeIcon icon={faInfoCircle} size={20} color="white" />
        </TouchableOpacity>
      </View>
      <View style={styles.profileDetails}>
        <Text style={styles.username}>{userProfile?.username}</Text>
        {renderBioSection()}
        {renderEditProfileButton()}
      </View>

      {renderCounts()}

      {/* Followers Modal */}
      {followers.length > 0 && (
        <UsersListModal
          users={followers}
          isVisible={showFollowersModal}
          toggleModal={() => setShowFollowersModal(false)}
        />
      )}

      {following.length > 0 && (
        <UsersListModal
          users={following}
          isVisible={showFollowingModal}
          toggleModal={() => setShowFollowingModal(false)}
        />
      )}

      <InfoModal visible={isInfoModalVisible} hideModal={toggleInfoModal} />

      <View style={{flex: 1, width: '100%'}}>
        <FlatList
          data={uploadedVideos}
          renderItem={renderVideoThumbnail}
          keyExtractor={item => item}
          numColumns={3}
          style={{paddingTop: 20}}
          contentContainerStyle={{alignItems: 'center'}}
          onRefresh={fetchUploadedVideos}
          refreshing={isRefreshing}
        />
      </View>
    </View>
  );
};

export default UserViewSelf;
