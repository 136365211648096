// QuestionForm.tsx
import React, { useEffect, useRef, useState, useCallback, useReducer } from 'react';
import { View, Text, TextInput, Button, StyleSheet, TouchableOpacity, Dimensions, Platform, Alert } from 'react-native';
import { useRoute, RouteProp, useNavigation, useIsFocused } from '@react-navigation/native';
import { RadioButton } from 'react-native-paper';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faArrowAltCircleLeft, faMinusCircle } from '@fortawesome/free-solid-svg-icons';
import { StackNavigationProp } from '@react-navigation/stack';
import axios from 'axios'; 
import { Video, ResizeMode } from 'expo-av';
import * as Progress from 'react-native-progress';

import { useAuth } from '../../context/AuthContext';
import { useVideoURI } from '../../context/VideoContext';
import { useRefetch } from '../../context/RefetchContext';
import { globalEvents } from '../../context/GlobalEvents';
// If you have a local GIF or image
import Image from '../UtilityComponents/UniversalImage';
import { API_URL } from '@env'; 
import styles from './style';

import { StackParamList } from '../../navigators/navigationTypes';

// ------- Types & Reducer Setup -------

type QuestionFormRouteParams = {
  // Matches what we'll pass from SubmitClip
  pausePoint: number;
  videoWidth: number;
  videoHeight: number;
  filename: string;
  file: File | null; // or undefined if not provided
};

type RootStackParamList = {
  QuestionForm: QuestionFormRouteParams;
};

type FormState = {
  question: string;
  options: string[];
  tags: string[];
  newTag: string;
  answer: number | null;
  isLoading: boolean;
  isVideoPlaying: boolean;
  progress: number;
  isAnswered: boolean;
};

type FormAction =
  | { type: 'SET_FIELD'; field: keyof FormState; value: any }
  | { type: 'ADD_OPTION' }
  | { type: 'REMOVE_OPTION'; index: number }
  | { type: 'ADD_TAG' }
  | { type: 'REMOVE_TAG'; tag: string };

const MAX_CHAR_LIMIT = 140;

function formReducer(state: FormState, action: FormAction): FormState {
  switch (action.type) {
    case 'SET_FIELD':
      return { ...state, [action.field]: action.value };
    case 'ADD_OPTION':
      return { ...state, options: [...state.options, ''] };
    case 'REMOVE_OPTION':
      return { ...state, options: state.options.filter((_, idx) => idx !== action.index) };
    case 'ADD_TAG':
      return { ...state, tags: [...state.tags, state.newTag], newTag: '' };
    case 'REMOVE_TAG':
      return { ...state, tags: state.tags.filter((tag) => tag !== action.tag) };
    default:
      return state;
  }
}

const initialState: FormState = {
  question: '',
  options: ['', ''],
  tags: [],
  newTag: '',
  answer: null,
  isLoading: false,
  isVideoPlaying: false,
  progress: 0,
  isAnswered: false,
};

// ------- Main Component -------

const QuestionForm: React.FC = () => {
  const route = useRoute<RouteProp<RootStackParamList, 'QuestionForm'>>();
  const navigation = useNavigation<StackNavigationProp<StackParamList>>();

  // Pull data from route params
  const { pausePoint, videoWidth, videoHeight, filename, file } = route.params;

  const { userProfile } = useAuth();
  const { videoURI } = useVideoURI();
  const { triggerRefetch } = useRefetch();

  const videoRef = useRef<Video>(null);
  const isFocused = useIsFocused();

  // Use reducer for form state
  const [state, dispatch] = useReducer(formReducer, initialState);
  const { question, options, tags, newTag, answer, isLoading, isVideoPlaying, progress, isAnswered } = state;

  // As soon as we mount or focus, set isVideoPlaying
  useEffect(() => {
    dispatch({ type: 'SET_FIELD', field: 'isVideoPlaying', value: isFocused });
  }, [isFocused]);

  // -------------- UI Helpers --------------
  const renderButton = (title: string, onPress: () => void) => {
    let color: string = Platform.OS === 'ios' ? 'white' : 'blue';
    const btn = <Button title={title} onPress={onPress} color={color} />;
    return Platform.OS === 'ios' ? <View style={styles.buttonContainer}>{btn}</View> : btn;
  };

  const handleQuestionChange = (text: string) => {
    dispatch({ type: 'SET_FIELD', field: 'question', value: text.slice(0, MAX_CHAR_LIMIT) });
  };

  const handleAddOption = () => {
    if (options.length < 4) {
      dispatch({ type: 'ADD_OPTION' });
    }
  };

  const handleRemoveOption = useCallback((index: number) => {
    dispatch({ type: 'REMOVE_OPTION', index });
  }, []);

  const handleChooseAnswer = (index: number) => {
    dispatch({ type: 'SET_FIELD', field: 'answer', value: index });
    dispatch({ type: 'SET_FIELD', field: 'isAnswered', value: true });
  };

  const handleAddTag = () => {
    if (newTag.trim() !== '' && !tags.includes(newTag)) {
      dispatch({ type: 'ADD_TAG' });
    }
  };

  const handleRemoveTag = useCallback((tag: string) => {
    dispatch({ type: 'REMOVE_TAG', tag });
  }, []);

  // -------------- Validation --------------
  const validateInputs = () => {
    // Question
    if (question.trim() === '' || question.length > MAX_CHAR_LIMIT) {
      Alert.alert('Error', 'Please enter a question within 140 characters.');
      return false;
    }
    // Options
    let nonEmptyCount = 0;
    for (const opt of options) {
      if (opt.trim() !== '') {
        nonEmptyCount++;
        if (opt.length > MAX_CHAR_LIMIT) {
          Alert.alert('Error', 'Each answer must be less than 140 characters.');
          return false;
        }
      }
    }
    if (nonEmptyCount < 2) {
      Alert.alert('Error', 'Please provide at least two answer options.');
      return false;
    }
    // Tags
    for (const tg of tags) {
      if (tg.length > MAX_CHAR_LIMIT) {
        Alert.alert('Error', 'Each tag must be less than 140 characters.');
        return false;
      }
    }
    // Answer
    if (!isAnswered) {
      Alert.alert('Error', 'You must select an answer');
      return false;
    }
    return true;
  };

  // -------------- Submission --------------
  const handleSubmit = async () => {
    if (!validateInputs()) return;

    dispatch({ type: 'SET_FIELD', field: 'isLoading', value: true });

    try {
      // 1) Upload the video file
      const videoFormData = new FormData();
      if (file) {
        videoFormData.append('file', file);
      }
      const videoUploadConfig = {
        onUploadProgress: (evt: any) => {
          const pct = Math.round((evt.loaded * 50) / evt.total);
          dispatch({ type: 'SET_FIELD', field: 'progress', value: pct / 100 });
        },
      };

      const videoUploadResponse = await axios.post(
        `${API_URL}/upload-video`,
        videoFormData,
        {
          ...videoUploadConfig,
          withCredentials: true,
        }
      );

      const { filename: uploadedFilename, aspectRatio } = videoUploadResponse.data;

      // 2) Upload the metadata
      const metadataFormData = new FormData();
      metadataFormData.append('question', question);
      metadataFormData.append('filename', uploadedFilename);
      metadataFormData.append('aspectRatio', aspectRatio);
      if (userProfile) {
        metadataFormData.append('userId', userProfile.username);
      }
      tags.forEach((tag, idx) => {
        metadataFormData.append(`tags[${idx}]`, tag);
      });
      options
        .filter((opt) => opt.trim() !== '')
        .forEach((opt, idx) => {
          metadataFormData.append(`answers[${idx}]`, opt);
        });
      metadataFormData.append('pausePoint', String(pausePoint));
      if (answer !== null) {
        metadataFormData.append('correctAnswer', String(answer));
      }

      const metadataUploadConfig = {
        onUploadProgress: (evt: any) => {
          const pct = 50 + Math.round((evt.loaded * 50) / evt.total);
          dispatch({ type: 'SET_FIELD', field: 'progress', value: pct / 100 });
        },
      };

      await axios.post(
        `${API_URL}/upload`,
        metadataFormData,
        {
          ...metadataUploadConfig,
          withCredentials: true,
        }
      );

      // 3) Associate the new video with the user’s account
      if (userProfile) {
        await axios.put(
          `${API_URL}/users/${userProfile.username}/upload/${uploadedFilename}`,
          {},
          { withCredentials: true }
        );
      }

      dispatch({ type: 'SET_FIELD', field: 'isLoading', value: false });

      // 4) All done—navigate to wherever you want next
      triggerRefetch();
      globalEvents.emit('videoUploaded');

      navigation.navigate('QuizViewerProfile', {
        videoIds: [uploadedFilename],
        initialVideoId: uploadedFilename,
        username: userProfile?.username ?? '',
      });
    } catch (error) {
      dispatch({ type: 'SET_FIELD', field: 'isLoading', value: false });
      console.error('Error uploading video and metadata:', error);
      Alert.alert('Error', 'Something went wrong while uploading.');
    }
  };

  // -------------- Render --------------
  const dynamicStyles = StyleSheet.create({
    videoPlayer: {
      position: 'absolute',
      width: videoWidth,
      height: videoHeight,
      alignSelf: 'center',
    },
  });

  return (
    <View style={{ flex: 1, backgroundColor: 'black' }}>
      {/* Video in the background */}
      {!isLoading && (
        <Video
          ref={videoRef}
          source={{ uri: videoURI || '' }}
          style={dynamicStyles.videoPlayer}
          resizeMode={ResizeMode.COVER}
          shouldPlay={isVideoPlaying}
          isLooping
        />
      )}

      {/* Overlay for form */}
      <View style={{ flex: 1, backgroundColor: 'rgba(0,0,0,0.5)' }}>
        {isLoading ? (
          <View style={styles.loadingContainer}>
            <Image source={require('../../assets/gifs/clipdle1.gif')} style={styles.gifStyle} />
            <Progress.Bar progress={progress} width={300} />
          </View>
        ) : (
          <View style={styles.overlay}>
            <View style={styles.container}>
              {/* Back Button */}
              <TouchableOpacity
                onPress={() => navigation.goBack()}
                style={styles.backButton}
              >
                <FontAwesomeIcon icon={faArrowAltCircleLeft} size={24} color="white" />
              </TouchableOpacity>

              <Text style={styles.header}>Submit Your Question</Text>

              <TextInput
                placeholder="Enter your question here"
                placeholderTextColor="white"
                style={styles.input}
                value={question}
                onChangeText={(text) => handleQuestionChange(text)}
                maxLength={MAX_CHAR_LIMIT}
              />

              <View style={{ marginTop: 20 }}>
                {options.map((option, index) => (
                  <View key={`option-${index}`} style={styles.optionRow}>
                    {Platform.OS === 'ios' ? (
                      // iOS-like "radio button" imitation
                      <TouchableOpacity
                        style={styles.radioButtonContainer}
                        onPress={() => handleChooseAnswer(index)}
                      >
                        <View
                          style={[
                            styles.radioButton,
                            answer === index && styles.radioButtonIcon,
                          ]}
                        />
                      </TouchableOpacity>
                    ) : (
                      // Android (react-native-paper) radio
                      <RadioButton
                        value={index.toString()}
                        status={answer === index ? 'checked' : 'unchecked'}
                        onPress={() => handleChooseAnswer(index)}
                      />
                    )}

                    <TextInput
                      placeholder={`Option ${index + 1}`}
                      placeholderTextColor="white"
                      style={styles.answerInput}
                      value={option}
                      onChangeText={(text) => {
                        const newOptions = [...options];
                        newOptions[index] = text;
                        dispatch({
                          type: 'SET_FIELD',
                          field: 'options',
                          value: newOptions,
                        });
                      }}
                      maxLength={MAX_CHAR_LIMIT}
                    />

                    {index >= 2 && (
                      <TouchableOpacity
                        style={{ padding: 5 }}
                        onPress={() => handleRemoveOption(index)}
                      >
                        <FontAwesomeIcon icon={faMinusCircle} size={24} color="red" />
                      </TouchableOpacity>
                    )}
                  </View>
                ))}

                {options.length < 4 && renderButton('Add Option', handleAddOption)}
              </View>

              {/* Tag Section */}
              <View style={{ marginTop: 20 }}>
                <Text style={styles.label}>Tags (optional):</Text>
                <View style={styles.tagInputContainer}>
                  <TextInput
                    placeholder="Add a tag"
                    placeholderTextColor="white"
                    value={newTag}
                    onChangeText={(text) =>
                      dispatch({
                        type: 'SET_FIELD',
                        field: 'newTag',
                        value: text.slice(0, MAX_CHAR_LIMIT),
                      })
                    }
                    style={styles.tagInput}
                  />
                  {renderButton('Add', handleAddTag)}
                </View>

                <View style={styles.tagsScrollView}>
                  {tags.map((tag, index) => (
                    <View key={`tag-${index}`} style={styles.tag}>
                      <Text style={{ color: 'white' }}>{tag}</Text>
                      <TouchableOpacity onPress={() => handleRemoveTag(tag)}>
                        <Text style={styles.removeTag}>×</Text>
                      </TouchableOpacity>
                    </View>
                  ))}
                </View>
              </View>

              <View style={{ marginTop: 20 }}>
                {renderButton('Submit', handleSubmit)}
              </View>
            </View>
          </View>
        )}
      </View>
    </View>
  );
};

export default QuestionForm;
