// AnswersModal.tsx
import React, { useState } from 'react';
import { Modal, View, Text, TouchableOpacity } from 'react-native';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import styles from './style';
import axios from 'axios';
import { useAuth } from '../../context/AuthContext';
import { useVideoInteraction } from '../../hooks/user/useVideoInteraction';

interface AnswersModalProps {
  isVisible: boolean;
  onClose: () => void;
  question: string;
  mongoId: string | null;
  videoId: string | null;
  answers: string[];
  // Updated to also provide the selectedAnswerIndex and correctness
  onAnswerSelect: (answer: string, index: number, isCorrect: boolean) => void;
  correctAnswer: number;
}

const AnswersModal: React.FC<AnswersModalProps> = ({
  isVisible,
  onClose,
  question,
  mongoId,
  videoId,
  answers,
  onAnswerSelect,
  correctAnswer
}) => {
  const [answerStatuses, setAnswerStatuses] = useState<(null | 'correct' | 'incorrect')[]>(Array(answers.length).fill(null));
  const { handleIncreaseScore, handleAnswerVideo } = useVideoInteraction();

  const handlePress = async (answer: string, index: number) => {
    const updatedStatuses = [...answerStatuses];
    let isCorrect = index === correctAnswer;

    if (isCorrect) {
      handleIncreaseScore(mongoId || '');
      updatedStatuses[index] = 'correct';
    } else {
      updatedStatuses[index] = 'incorrect';
      updatedStatuses[correctAnswer] = 'correct';
    }
    setAnswerStatuses(updatedStatuses);

    // Add the video to the user's answered videos after processing
    await handleAnswerVideo(videoId || '', index, isCorrect);

    // Now call onAnswerSelect with additional info
    onAnswerSelect(answer, index, isCorrect);
  };

  return (
    <Modal
      animationType="slide"
      transparent={true}
      visible={isVisible}
      onRequestClose={onClose}
    >
      <TouchableOpacity
        style={styles.centeredView}
        activeOpacity={1}
        onPressOut={onClose}
      >
        <View style={styles.modalView} onStartShouldSetResponder={() => true}>
          <TouchableOpacity style={styles.closeButton} onPress={onClose}>
            <FontAwesomeIcon icon={faTimes} color="white" size={24} />
          </TouchableOpacity>

          <Text style={styles.modalText}>{question}</Text>
          {answers.map((answer, index) => (
            <TouchableOpacity
              key={index}
              style={[
                styles.answerButton,
                answerStatuses[index] === 'correct'
                  ? styles.correct
                  : answerStatuses[index] === 'incorrect'
                  ? styles.incorrect
                  : {},
              ]}
              onPress={() => handlePress(answer, index)}
              disabled={answerStatuses.some(status => status !== null)}
            >
              <Text style={styles.answerText}>{answer}</Text>
              {answerStatuses[index] && (
                <FontAwesomeIcon
                  icon={answerStatuses[index] === 'correct' ? faCheck : faTimes}
                  color={answerStatuses[index] === 'correct' ? 'green' : 'red'}
                  size={20}
                />
              )}
            </TouchableOpacity>
          ))}
        </View>
      </TouchableOpacity>
    </Modal>
  );
};

export default AnswersModal;
