import React, { useState } from 'react';

import axios from "axios";
import AsyncStorage from '@react-native-async-storage/async-storage';
import { User } from '../../hooks/user/UserTypes';

import { API_URL } from '@env';

export const useSearch = () => {

    const [users, setUsers] = useState<User[]>([]);
    const [loading, setLoading] = useState(false);
    const [topUsers, setTopUsers] = useState<User[]>([]); // State to store top users

    const handleSearch = async (searchQuery: string) => 
    {
        setLoading(true);
        try 
        {
        const response = await axios.get(`${API_URL}/users/find/${searchQuery}`,
            {
                withCredentials: true,
            }
        );
        
        // Check if the response is an array, otherwise wrap it in an array
        const usersData = Array.isArray(response.data) ? response.data : [response.data];
        setUsers(usersData);
    
        }  
        catch (error) 
        {
        if (axios.isAxiosError( error )) 
            {
            const status = error.response?.status;
            const data = error.response?.data;
            const statusText = error.response?.statusText || 'No additional error message provided';
            const errorMessage = error.response?.data?.message || 'Error occurred during search';
            
            console.error('Axios error:', status, data, statusText, errorMessage);
        } 
        else 
        {
            console.error('Error searching for users:', error);
        }
        setUsers([]);
        }
        setLoading(false);
    };

    const fetchTopUsers = async () => {
        try {
            const storedData = await AsyncStorage.getItem('topUsersData');
            if (storedData) {
                const data = JSON.parse(storedData);
                setTopUsers(data.topUsers); // Set the top users from local storage
            }
        } catch (error) {
            console.error('Error fetching top users from storage:', error);
        }
    };

    return {
        // Login state and functions
        users,
        setUsers,
        topUsers,
        setTopUsers,
        loading,
        handleSearch,
        fetchTopUsers,
      };


};