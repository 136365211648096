import React, { useState, useEffect, useRef, useCallback } from 'react';
import { View, FlatList, Dimensions, ActivityIndicator, TouchableOpacity, StatusBar, Platform, ViewStyle } from 'react-native';
import { Video, ResizeMode, AVPlaybackStatus } from 'expo-av';
import QuizOverlay from '../QuizOverlay/QuizOverlay';
import { useIsFocused } from '@react-navigation/native';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faRepeat, faPlay } from '@fortawesome/free-solid-svg-icons'; // Import the replay icon
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import styles from './style';
import { StackScreenProps } from '@react-navigation/stack'; // <- from @react-navigation/stack
import { RootStackParamList } from '../../linkingConfig';   // exported from linkingConfig
import { useAuth } from '../../context/AuthContext';
import CompactLoginModal from './CompactLoginModal';

import {
  ProgressData,
  VideoData,
} from '../Quiz/QuizTypes';

type Props = StackScreenProps<RootStackParamList, 'PublicVideoScreen'>;

const VID_HEIGHT = Dimensions.get('window').height - (StatusBar.currentHeight || 0);

import { API_URL } from '@env';
import axios from 'axios';

const PublicVideoScreen = ({ route, navigation }: Props) => {
  const [videos, setVideos] = useState<VideoData[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isPaused, setIsPaused] = useState(false);
  const [showReplayButton, setShowReplayButton] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [questionAnswered, setQuestionAnswered] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [currentVideo, setCurrentVideo] = useState<VideoData | null>(null);
  const [showPlayButton, setShowPlayButton] = useState(true);

  const { isAuthenticated, userProfile } = useAuth();

  const { videoIds, initialVideoId, username } = route.params ?? {};

  const flatListRef = useRef<FlatList<VideoData> | null>(null);
  const videoRefs = useRef(new Map());
  const videoRef = useRef<Video>(null);

  const insets = useSafeAreaInsets();
  const bottomInset = insets.bottom || 0;

  const WINDOW_HEIGHT =
    Platform.OS === 'android' && Platform.Version >= 29
      ? Dimensions.get('window').height + (StatusBar.currentHeight || 0)
      : Dimensions.get('window').height;

  const tabBarHeight = 55; // Height of your BottomTabNavigator
  const availableHeight = WINDOW_HEIGHT - (StatusBar.currentHeight || 0) - tabBarHeight - bottomInset;

  const windowWidth = Dimensions.get('window').width;
  const windowHeight = Dimensions.get('window').height;
  const videoAspectRatio = 9 / 16; // Adjust this based on your video's aspect ratio

  // Dynamically create styles that depend on availableHeight
  const dynamicStyles: { videoContainer: ViewStyle; fullScreenVideo: ViewStyle } = {
    videoContainer: {
      width: undefined,  // This is a valid string for width
      height: WINDOW_HEIGHT,  // This is a number, so it's valid for height
      justifyContent: 'center',
      alignItems: 'center',
    },
    fullScreenVideo: {
      aspectRatio: videoAspectRatio,
      width: undefined,
      height: '100%',
      alignSelf: 'center',
    },
  };

  useEffect(() => {
        if(isAuthenticated && userProfile?.username) {
            navigation.navigate('MainTab', {
                screen: 'QuizViewerProfile',
                params: {
                videoIds: videoIds || [],
                initialVideoId: initialVideoId || '',
                username: username || '',
                },
            });
        }
    }, []);

  // Function to fetch specific video data
  const fetchVideoData = async (id: string) => {
    try {
      const response = await axios.get(`${API_URL}/videos/${id}`);
      const data: VideoData = await response.data;
      return data;
    } catch (error) {
      console.error('Failed to fetch video:', error);
    }
  };

  useEffect(() => {
    if (currentVideo && currentVideo.id) {
      const player = videoRef.current;
      if (player) {
        if(isPaused) {
          player.pauseAsync();
        } else {
          player.playAsync();
        }
      } 
    }
  }, [isPaused]);

  const scrollToIndexFailed = (info: any) => {
    const wait = new Promise(resolve => setTimeout(resolve, 500));
    wait.then(() => {
      flatListRef.current?.scrollToIndex({ index: info.index, animated: true });
    });
  };

  const onProgress = useCallback((data: ProgressData, videoId: string) => {
    setCurrentTime(data.currentTime);

    // Check if currentVideo and pausePoint are defined
    if (currentVideo && currentVideo.pausePoint !== undefined) {

      setShowPlayButton(false);
      if (data.currentTime >= currentVideo.pausePoint && !questionAnswered) {
        const player = videoRef.current;
        if(player) {
          player.pauseAsync();
        }
        setIsPaused(true);
        setShowReplayButton(true);
      }
    }
  }, [currentVideo, questionAnswered, videoRefs]);

  // Function to handle replaying the video
  const handleReplay = () => {
    if (currentVideo && currentVideo.id) {
      const player = videoRef.current;
      if (player) {
        // Use setPositionAsync to seek to the beginning
        player.setPositionAsync(0).then(() => {
          // Optionally, you can handle any logic after the seek operation here
          player.playAsync(); // Resume playing after seeking
        });
      } else {
        
      }
      setShowReplayButton(false);
    }
  };

  const ReplayButton = () => (
    <TouchableOpacity
      style={styles.replayButtonContainer}
      onPress={handleReplay}
    >
      <FontAwesomeIcon icon={faRepeat} size={50} color="#FFF" />
    </TouchableOpacity>
  );

  const PlayButton = () => (
    <TouchableOpacity
      style={styles.playButtonContainer}
      onPress={handlePlay}
    >
      <FontAwesomeIcon icon={faPlay} size={50} color="#FFF" />
    </TouchableOpacity>
  );

  const handlePlay = () => {
    if (videoRef.current) {
      videoRef.current.playAsync();
      setShowPlayButton(false);
    }
  };

  const getVideoStyle = (aspectRatioString: string) => {
    const [widthStr, heightStr] = aspectRatioString.split(':');
    const width = parseInt(widthStr, 10);
    const height = parseInt(heightStr, 10);
    const aspectRatio = width / height;
  
    const isMobileFormat = height > width;
  
    if (!isMobileFormat) {
      // Fullscreen style for non-mobile format videos
      return {
        width: '100%', // Width as a string is acceptable for style
        height: '100%', // Height as a string is acceptable for style
        alignSelf: 'center' as const, // Ensure alignSelf uses a valid string value
      };
    } else {
      // Maintain original aspect ratio for mobile format videos
      const videoWidth = Math.min(windowWidth, windowHeight * aspectRatio);
      const videoHeight = videoWidth / aspectRatio;
      return {
        aspectRatio: aspectRatio,
        width: videoWidth, // Ensure width is a number
        height: videoHeight, // Ensure height is a number
        alignSelf: 'center' as const, // Ensure alignSelf uses a valid string value
      };
    }
  };

  // Instead of navigating, open the modal if not authenticated:
  const openLoginModal = () => {
    if (!isAuthenticated || !userProfile?.username) {
      setShowLoginModal(true);
    } else {
      navigation.navigate('MainTab', {
        screen: 'QuizViewerProfile',
        params: {
          videoIds: videoIds ?? [],
          initialVideoId: initialVideoId ?? '',
          username: username ?? '',
        },
      });
    }
  };

  const navigateLogin = () => {
    if (!isAuthenticated || !userProfile?.username) {
      navigation.navigate('LoginNavigatorScreen');
    }
    else
    {
      navigation.navigate('LoginNavigatorScreen', {
        redirectTo: 'MainTab',
        screen: 'QuizViewerProfile',
        params: { videoIds: videoIds ?? [], initialVideoId: initialVideoId ?? '', username: username ?? '' },
      });
    }
  };

  const renderItem = ({ item, index }: { item: VideoData, index: number }) => {

    const videoStyle = getVideoStyle(item.aspectRatio);

      return (
        <View style={dynamicStyles.videoContainer}>

          <Video
            source={{ uri: item.source }}
            style={videoStyle as ViewStyle}
            videoStyle={videoStyle as ViewStyle}
            isLooping
            resizeMode={ResizeMode.COVER as ResizeMode}
            shouldPlay={true}
            onLoad={() => setIsPaused(false)}
            ref={videoRef}
            onPlaybackStatusUpdate={handleOnPlaybackStatusUpdate(item.id)}
          />

          {isLoading && (
            <View style={styles.loadingContainer}>
              <ActivityIndicator size="large" color="#00ff00" />
            </View>
          )}

          {showPlayButton && <PlayButton />}
          {showReplayButton && <ReplayButton />}

          <QuizOverlay 
            mongoId={item._id}
            question={item.question as string}
            GCSId={item.id}
            isBlocked={false}
            answers={item.answers || []}
            onAnswerSelect={() => openLoginModal()}
            correctAnswer={item.correctAnswer}
            uploaderUsername={username ?? null}
            isFromProfile={true}
            // onVideoInteraction expects Partial<WatchedVideoData>
            onVideoInteraction={() => openLoginModal()}
          />
        </View>
      );
  }

  const getItemLayout = (data: ArrayLike<VideoData> | null | undefined, index: number) => ({
    length: VID_HEIGHT,
    offset: VID_HEIGHT * index,
    index,
  });

  // Adjusted handleOnPlaybackStatusUpdate function
  const handleOnPlaybackStatusUpdate = useCallback((videoId: string) => (status: AVPlaybackStatus) => {
    if (status.isLoaded) {
      if (status.isPlaying) {
        // Update progress
        const currentTime = status.positionMillis / 1000;
        onProgress({ currentTime }, videoId);
      }
      // Add other status handling if needed
    }
  }, [onProgress]);

  // useEffect to ensure the video plays when the ref is set
  useEffect(() => {
    if (currentVideo && currentVideo.id) {
      if(videoRef){
        const player = videoRef;
        if (player) {
          player.current?.playAsync();
        }
      }
    }
  }, [currentVideo, videoRef]);

  useEffect(() => {
    const loadVideos = async () => {
      setIsLoading(true);
      
      if( initialVideoId == null ) {
        if( isAuthenticated && userProfile?.username) {
          navigation.navigate('MainTab', {
            screen: 'QuizStart'
          });
        }
        else
        {
          navigation.navigate('LoginNavigatorScreen', {
            redirectTo: 'MainTab',
            screen: 'QuizStart'
          });
        }
      }
      
      try {
        
        const videoDataPromises = (videoIds ?? []).map((id: string) => fetchVideoData(id));
        const videoData = await Promise.all(videoDataPromises);
        const filteredVideoData = videoData.filter((v: any): v is VideoData => v !== undefined);
        setVideos(filteredVideoData);
        
        // Set the initial video if necessary
        if (initialVideoId) {
          
          const initialVideo = filteredVideoData.find((video: { id: any; }) => video.id === initialVideoId);
          if (initialVideo) {
            
            setCurrentVideo(initialVideo);
            if(videoRefs.current) {
              
              const player = videoRefs.current.get(initialVideo.id);
              
              if (player) {
                
                player.playAsync();
              }
            }
          }
        }
      } catch (error) {
        console.error('Failed to load videos:', error);
      } finally {
        setIsLoading(false);
      }
    };

    loadVideos();
  }, [videoIds, initialVideoId]);
  
  useEffect(() => {
    const scrollToSelectedVideo = () => {
      
      if (initialVideoId && videos.length > 0) {
        const initialIndex = videos.findIndex(video => video.id === initialVideoId);
        
        if (initialIndex !== -1) {
          flatListRef.current?.scrollToIndex({animated: false, index: initialIndex});
        }
      }
    };
  
    if (!isLoading) {
      scrollToSelectedVideo();
    }
  }, [initialVideoId, videos, isLoading]);

  const isFocused = useIsFocused();

  useEffect(() => {
    const player = videoRef.current;
    if(isFocused){
      if (player) {
        player.playAsync();
      }
    } else {
      if (player) {
        player.pauseAsync();
      }
    }
  }, [isFocused]);

  return (
    <View style={styles.quizContainer}>

      <CompactLoginModal
        visible={showLoginModal}
        onClose={() => setShowLoginModal(false)}
        // if you want to do something after success:
        onLoginSuccess={() => {
          // e.g. navigate to profile or do something else
          navigation.navigate('MainTab', {
            screen: 'QuizViewerProfile',
            params: {
              videoIds: videoIds ?? [],
              initialVideoId: initialVideoId ?? '',
              username: username ?? '',
            },
          });
        }}
      />

      <FlatList
        data={videos}
        ref={flatListRef}
        pagingEnabled={true}
        renderItem={renderItem}
        keyExtractor={item => item.id}
        getItemLayout={getItemLayout}
        onScrollToIndexFailed={scrollToIndexFailed}
        showsVerticalScrollIndicator={false}
      />
    </View>
  );
};

export default PublicVideoScreen;