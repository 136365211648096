import { StyleSheet, Dimensions, StatusBar } from "react-native";

const VID_HEIGHT = Dimensions.get('window').height - (StatusBar.currentHeight || 0);

const styles = StyleSheet.create({
  quizContainer: {
    flex: 1,
    height: '100%',
    backgroundColor: 'black',
  },
  replayButtonContainer: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: [{ translateX: -45 }, { translateY: -45 }],
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 50,
    padding: 20,
    backgroundColor: 'rgba(0, 0, 0, 0.75)'
  },
  backButton: {
    position: 'absolute', 
    zIndex: 5,
    top: '5%',
    left: '5%'
  },
  playButtonContainer: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: [{ translateX: -45 }, { translateY: -45 }],
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 50,
    padding: 20,
    backgroundColor: 'rgba(0, 0, 0, 0.75)'
  },
  videoContainer: {
    width: '100%',
    height: '100%',
  },
  fullScreenVideo: {
    position: 'relative',
    height: '100%',
    width: '100%',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  loadingContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(255,255,255,0.8)',
  },  
});

export default styles;
