// linkingConfig.ts
import * as Linking from 'expo-linking';
import { LinkingOptions, PathConfigMap } from '@react-navigation/native';
import { NavigatorScreenParams } from '@react-navigation/native';

const prefix = Linking.createURL('/');

export type RootStackParamList = {
  PublicVideoScreen: {
    videoIds?: string[];
    initialVideoId?: string;
    username?: string;
  };
  MainTab: NavigatorScreenParams<MainTabParamList>;
  LoginNavigatorScreen:
    | undefined
    | {
        redirectTo?: string;
        screen?: string;
        params?: {
          videoIds?: string[];
          initialVideoId?: string;
          username?: string;
        };
      };
  LauncherScreen: undefined;
  // ... other root stack screens
};

export type MainTabParamList = {
  UserViewSelfStart: undefined;
  UserSearchStart: undefined;
  QuizStart: undefined;
  SubmitClipStart: undefined;
  QuizViewerProfile: {
    videoIds: string[];
    initialVideoId: string;
    username: string;
  };
  // ... other screens in MainTabNavigator
};

const MainTabScreenConfig: PathConfigMap<MainTabParamList> = {
  UserViewSelfStart: 'userview',
  UserSearchStart: 'usersearch',
  QuizStart: 'quiz',
  SubmitClipStart: 'submit',
  QuizViewerProfile: {
    parse: {
      videoIds: (videoIds: string) => [videoIds],
      initialVideoId: (videoId: string) => videoId,
      username: (username: string) => username,
    },
  },
  // ... other screens in MainTabNavigator
};

export const linking: LinkingOptions<RootStackParamList> = {
  prefixes: [prefix, 'clipdle://', 'https://clipdle.tv', 'http://localhost:19006'],
  config: {
    screens: {
      PublicVideoScreen: {
        path: 'video/:videoIds/:initialVideoId/:username',
        parse: {
          videoIds: (videoIds: string) => [videoIds],
          initialVideoId: (videoId: string) => videoId,
          username: (username: string) => username,
        },
      },
      MainTab: {
        screens: MainTabScreenConfig,
      },
      LoginNavigatorScreen: 'login',
      // ... other root stack screens
    },
  },
};