import React, { useRef, useState } from 'react';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { Platform, StyleSheet, View, Animated } from 'react-native';
import UserStartNavigator, { userStartStackRef } from './UserStartNavigator';
import QuizStartNavigator, { quizStackRef } from './QuizStartNavigator';
import SubmitClipNavigator, { submissionStackRef } from './SubmitClipNavigator';
import UserSearchNavigator, { userSearchStackRef } from './UserSearchNavigator';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faFilm, faPlusCircle, faSearch, faPerson } from '@fortawesome/free-solid-svg-icons';
import { StackParamList } from './navigationTypes';
import { widthPercentageToDP as wp, heightPercentageToDP as hp } from 'react-native-responsive-screen';
import UserView from '../components/UserView/UserView';
import UserViewOther from '../components/UserViewOther/UserViewOther';
import Login from '../components/Login/Login';
import QuizViewerProfile from '../components/QuizViewerProfile/QuizViewerProfile';
import { StackActions } from '@react-navigation/native';

const Tab = createBottomTabNavigator<StackParamList>();

let topMargin: number;

if (Platform.OS === 'ios') {
  topMargin = hp('2%'); // example value, adjust as needed
} else {
  topMargin = 0;
}

function MainTabNavigator() {
  const [currentTab, setCurrentTab] = useState('QuizStart');

  const waveAnimations = {
    QuizStart: useRef(new Animated.Value(0)).current,
    SubmitClipStart: useRef(new Animated.Value(0)).current,
    UserSearchStart: useRef(new Animated.Value(0)).current,
    UserViewSelfStart: useRef(new Animated.Value(0)).current,
  };

  const AnimatedIcon = ({ icon, color, size, waveAnimation }: { icon: any; color: string; size: number; waveAnimation: Animated.Value }) => {
    const scale = waveAnimation.interpolate({
      inputRange: [0, 0.5, 1],
      outputRange: [1, 1.2, 1],
    });

    const opacity = waveAnimation.interpolate({
      inputRange: [0, 0.5, 1],
      outputRange: [0, 1, 0],
    });

    return (
      <View style={{ alignItems: 'center', justifyContent: 'center' }}>
        <Animated.View
          style={{
            position: 'absolute',
            width: size * 2,
            height: size * 2,
            borderRadius: size,
            borderWidth: 2,
            borderColor: color,
            opacity,
            transform: [{ scale }],
          }}
        />
        <FontAwesomeIcon style={{ top: topMargin }} icon={icon} size={size} color={color} />
      </View>
    );
  };

  const animateTab = (toTab: keyof typeof waveAnimations) => {
    // Reset all animations
    (Object.keys(waveAnimations) as Array<keyof typeof waveAnimations>).forEach((tab) => {
      waveAnimations[tab].setValue(0);
    });

    // Animate the wave effect to the new tab
    Animated.timing(waveAnimations[toTab], {
      toValue: 1,
      duration: 300,
      useNativeDriver: true,
    }).start();
  };

  const getTabBarActiveTintColor = (focused: boolean) => {
    return focused ? '#007AFF' : 'gray';
  };

  return (
    <View style={styles.container}>
      <Tab.Navigator
        screenOptions={{
          headerShown: false,
          tabBarActiveTintColor: '#007AFF',
          tabBarInactiveTintColor: 'gray',
          tabBarStyle: styles.tabBar,
        }}
        screenListeners={{
          state: (e: any) => {
            const newRoute = e.data.state.routes[e.data.state.index];
            setCurrentTab(newRoute.name);
          },
        }}
      >
        <Tab.Screen
          name="QuizStart"
          component={QuizStartNavigator}
          options={{
            title: 'Quiz',
            tabBarLabel: () => null,
            tabBarIcon: ({ focused, color, size }) => (
              <AnimatedIcon
                icon={faFilm}
                color={getTabBarActiveTintColor(focused)}
                size={size}
                waveAnimation={waveAnimations.QuizStart}
              />
            ),
          }}
          listeners={({ navigation }) => ({
            tabPress: (e) => {
              e.preventDefault();
              animateTab('QuizStart');
              // We do a short delay for your "wave" effect
              setTimeout(() => {
                // Instead of navigate('QuizStart'), do a stack reset:
                quizStackRef.current?.dispatch(StackActions.popToTop());
                // Then also ensure the tab is “focused.”
                navigation.navigate('QuizStart');
              }, 300);
            },
          })}
        />

        <Tab.Screen
          name="SubmitClipStart"
          component={SubmitClipNavigator}
          options={{
            title: 'Upload Clip',
            tabBarLabel: () => null,
            tabBarIcon: ({ focused, color, size }) => (
              <AnimatedIcon
                icon={faPlusCircle}
                color={getTabBarActiveTintColor(focused)}
                size={size}
                waveAnimation={waveAnimations.SubmitClipStart}
              />
            ),
          }}
          listeners={({ navigation }) => ({
            tabPress: (e) => {
              e.preventDefault();
              animateTab('SubmitClipStart');
              setTimeout(() => {
                submissionStackRef.current?.dispatch(StackActions.popToTop());
                navigation.navigate('SubmitClipStart'); // Adjust duration as needed
              }, 300);
            },
          })}
        />

        <Tab.Screen
          name="UserSearchStart"
          component={UserSearchNavigator}
          options={{
            tabBarLabel: () => null,
            tabBarIcon: ({ focused, color, size }) => (
              <AnimatedIcon
                icon={faSearch}
                color={getTabBarActiveTintColor(focused)}
                size={size}
                waveAnimation={waveAnimations.UserSearchStart}
              />
            ),
          }}
          listeners={({ navigation }) => ({
            tabPress: (e) => {
              e.preventDefault();
              animateTab('UserSearchStart');
              setTimeout(() => {
                userSearchStackRef.current?.dispatch(StackActions.popToTop());
                navigation.navigate('UserSearchStart');
              }, 300); // Adjust duration as needed
            },
          })}
        />

        <Tab.Screen
          name="UserViewSelfStart"
          component={UserStartNavigator}
          options={{
            tabBarLabel: () => null,
            tabBarIcon: ({ focused, color, size }) => (
              <AnimatedIcon
                icon={faPerson}
                color={getTabBarActiveTintColor(focused)}
                size={size}
                waveAnimation={waveAnimations.UserViewSelfStart}
              />
            ),
          }}
          listeners={({ navigation }) => ({
            tabPress: (e) => {
              e.preventDefault();
              animateTab('UserViewSelfStart');
              setTimeout(() => {
                userStartStackRef.current?.dispatch(StackActions.popToTop());
                navigation.navigate('UserViewSelfStart');
               },  300); // Adjust duration as needed
            },
          })}
        />

        <Tab.Screen
          name="QuizViewerProfile"
          component={QuizViewerProfile}
          options={{ tabBarButton: () => <View /> }} // Hide the tab bar button
        />

        <Tab.Screen
          name="UserViewOther"
          component={UserViewOther}
          options={{ tabBarButton: () => <View /> }} // Hide the tab bar button
        />

        <Tab.Screen
          name="UserView"
          component={UserView}
          options={{ tabBarButton: () => <View /> }} // Hide the tab bar button
        />

        <Tab.Screen
          name="LoginScreen"
          component={Login}
          options={{ tabBarButton: () => <View /> }} // Hide the tab bar button
        />

      </Tab.Navigator>
    </View>
  );
}

const styles = StyleSheet.create({
  wrapper: {
    flex: 1,
    marginBottom: 55, // height of your BottomTabNavigator
  },
  container: {
    flex: 1,
    backgroundColor: 'black', // This might be overridden by the gradient
  },
  tabBar: {
    zIndex: 4,
    width: '100%',
    height: 55,
    backgroundColor: 'rgba(24, 24, 24, 1)',
    justifyContent: 'space-evenly',
    borderTopWidth: 0,
    elevation: 0,
    shadowOpacity: 0,
  },
});

export default MainTabNavigator;
