import { StyleSheet, Dimensions } from "react-native";

const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 84,
    height: '100%'
  },
  commentContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    padding: 10,
    marginVertical: 5,
    width: '100%'
  },
  commentAvatar: {
    width: 32, // smaller size for the thumbnail
    height: 32,
    borderRadius: 16,
    marginRight: 8, // Add some spacing between the avatar and text
  },
  commentTextContainer: {
    flex: 1,
  },
  userIdText: {
    color: 'grey',
    fontSize: 12,
  },
  commentText: {
    color: 'white',
    alignContent: 'center',
    textAlign: 'left',
    fontSize: 14,
  },
  modalView: {
    margin: 10,
    width: '100%',
    height: '100%',
    backgroundColor: '#18191A',
    borderRadius: 20,
    padding: 55,
    alignItems: 'center',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  // Inside your styles object
  deleteButton: {
    padding: 5,
  },
  commentContent: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    flex: 1,
  },
  textInput: {
    flex: 1, // Take up as much space as possible
    color: 'white',
    borderRadius: 5,
    textAlignVertical: 'top',
    overflow: 'hidden',
    marginRight: 10, // Add some margin on the right for spacing
    fontSize: 14,
    padding: 10, // Add some padding inside the text input
  },
  textInputContainer: {
    flexDirection: 'row', // Align children horizontally
    justifyContent: 'space-between', // Space out children evenly
    alignItems: 'center', // Align children vertically in the center
    width: '100%',
    backgroundColor: 'gray',
    paddingHorizontal: 10, // Add some padding on the sides
    paddingVertical: 5, // Add some padding on the top and bottom
  },
  buttonSubmit: {
    paddingVertical: 10,
    paddingHorizontal: 20,
    backgroundColor: '#2069e0',
    borderRadius: 5,
  },
  button: {
    borderRadius: 20,
    padding: 10,
    elevation: 2,
  },
  buttonOpen: {
    backgroundColor: '#2069e0',
  },
  buttonClose: {
    backgroundColor: '#2069e0',
  },
  closeButtonContainer: {
    position: 'absolute',
    top: 10,
    right: 10,
    zIndex: 1,
  },
  closeButton: {
    borderRadius: 15,
    padding: 5,
    elevation: 2,
  },
  closeButtonText: {
    color: 'white',
    fontWeight: 'bold',
    fontSize: 24,
    textAlign: 'center',
  },
  
  textStyle: {
    color: 'white',
    fontWeight: 'bold',
  },
  grabber: {
    width: 80, // Width of the grabber
    height: 6, // Height of the grabber
    borderRadius: 3, // Rounded corners
    backgroundColor: '#ccc', // Color of the grabber
    alignSelf: 'center', // Center the grabber horizontally
    marginTop: 20, // Margin at the top
    marginBottom: 8, // Space below the grabber
    position: 'absolute'
  },
  modalText: {
    marginBottom: 15,
    textAlign: 'center',
    color: 'white'
  },
});

export default styles;
