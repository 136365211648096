import React from 'react';
import { View, Text, TouchableOpacity, StyleSheet, Modal as RNModal } from 'react-native';

type ModalProps = {
    isOpen: boolean;
    title: string;
    children: React.ReactNode;
    onClose: () => void;
  };  

  const Modal: React.FC<ModalProps> = ({ isOpen, title, children, onClose }) => {
    if (!isOpen) return null;
  
    return (
      <RNModal
        animationType="slide"
        transparent={true}
        visible={isOpen}
        onRequestClose={onClose}
      >
        <TouchableOpacity style={styles.overlay} onPress={onClose} testID="modal-overlay">
          <View style={styles.contentContainer}>
            <TouchableOpacity 
              style={styles.content} 
              onPress={(e) => e.stopPropagation()} 
              testID="modal-content"
            >
              <Text style={styles.title}>{title}</Text>
              {children}
            </TouchableOpacity>
          </View>
        </TouchableOpacity>
      </RNModal>
    );
  };
  
  const styles = StyleSheet.create({
    overlay: {
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      width: '100%', // Ensure it covers full width
      height: '100%', // Ensure it covers full height
    },
    contentContainer: {
      width: '50%', // Container to control the modal width
      justifyContent: 'center',
      alignItems: 'center',
    },
    content: {
      width: '100%', // This will be 100% of the contentContainer's width, which is 50% of the screen
      padding: 20,
      backgroundColor: 'white',
      borderRadius: 10,
    },
    title: {
      fontSize: 20,
      fontWeight: 'bold',
      marginBottom: 10,
    },
});

export default Modal;
