import React, { useState } from 'react';
import { useAuth } from '../../context/AuthContext';
import { getAuth } from 'firebase/auth';

import axios from "axios";
import { Alert } from 'react-native';

import { API_URL } from '@env';

export const useAdmin = () => {

    const { getToken } = useAuth();

    const banUser = async (username: string) => {
        console.log("Banning user:", username)
        try {
            await axios.put(
                `${API_URL}/admin/ban/${username}`, 
                {},
                {
                    withCredentials: true,
                }
            );
            Alert.alert("Success", `User ${username} has been banned.`);
        } catch (error) {
            console.error('Error banning user:', error);
            Alert.alert("Error", "Failed to ban user.");
        }
      };

    return {
        // Login state and functions
        banUser,
    };


};