import React, { useState } from 'react';

import axios from "axios";
import { useAuth } from '../../context/AuthContext';
import { getAuth } from 'firebase/auth';

import { API_URL } from '@env';

export const useVideoInteraction = () => {

    const { userProfile, updateWatchedVideos: updateWatchedProfileVideos, getToken } = useAuth();

    const handleIncreaseScore = async (mongoId: string) => {

      // Increase the score
      if(userProfile && userProfile.username){
        const response = await axios.patch(`${API_URL}/users/${userProfile.username}/${mongoId}/increaseScore`,
          {}, 
          {
            withCredentials: true,
          }
        );
      }
    };
    
    const handleAnswerVideo = async (videoId: string, userAnswerIndex: number, answeredCorrectly: boolean) => {
      if (userProfile && userProfile.username) {
        const response = await axios.patch(
          `${API_URL}/users/${userProfile.username}/answerVideo/${videoId}`,
          {
            userAnswerIndex,
            answeredCorrectly
          },
          {
            withCredentials: true,
          }
        );
      }
    };

    return {
        // Login state and functions
        handleIncreaseScore, 
        handleAnswerVideo
      };

};